import React from 'react';
import { Box, Typography } from '@mui/material';
import { blueGrey, grey } from '@mui/material/colors';
import { ResponsiveContainer } from 'recharts';


export function GraphBox({ title, children }) {
    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            rowGap={2}
            alignItems={'center'}
            justifyContent={'center'}
            sx={{
                mt: 3,
                backgroundColor: blueGrey[900],
                ml:5,
                boxShadow: 3,

            }}
        >
            <Box>
                <Typography
                    variant={'h5'}
                    sx={{
                        mb:2,
                        mt:2,
                        color: grey[100],
                        fontWeight: 500,
                    
                    }}
                >{title}</Typography>
            </Box>
            <Box
                sx={{m1:3}}
            >
                <ResponsiveContainer width={1200} height={500}>
                {children}
                </ResponsiveContainer>
            </Box>
        </Box>
    )
}