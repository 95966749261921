import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import DialogTitle from '@mui/material/DialogTitle';
import theme from '../../../theme'
import { Alert, Autocomplete, Box, Checkbox, FormControl, FormControlLabel, FormGroup, Select, Snackbar } from '@mui/material';
import api from '../../../services/api';




export default function FormDialog(props) {
  const [handlerSnack, setHandlerSnack] = React.useState()
  
  const item = {
    empresa_id : props.empresa_id,
    description: '',
    host: '',
    username: '',
    password: '',
    observacao: '',

  }
  const [state, setState] = React.useState(item);

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };


  React.useEffect(()=>{
    getEditInfo()
 
  },[props.id, handlerSnack])


  const getEditInfo = ()=>{
    setState(item)
    if(!props.id) return
    api.get(`empresa/acesso/find/${props.id}`)
    .then(res=>{
      let data = res.data.__data__
      
      setState(data)
  

    }).catch(err=> setState(item))
  }
  const showNotify = (msg,sev="success")=>{
    setMsgSnack(msg)
    setSevSnack(sev)
    setHandlerSnack(true)
  }
  
  const [ msgSnack, setMsgSnack ] = React.useState('')
  const [ sevSnack, setSevSnack ] = React.useState('')

  

 
  const handleSubmit = (event)=>{
    console.log(state)
    const handlerClose = ()=>{
      props.handlerClose()
    }
    event.preventDefault()
    if(!props.id){
      api.post('empresa/acesso/create', state)
      .then(()=>{
          showNotify("Registro adicionado com sucesso.")
          handlerClose()
      }).catch(()=>{
          showNotify("Um erro ocorreu...",'error')
      })
    }else{
      api.put(`empresa/acesso/edit/${props.id}/`, state)
      .then(()=>{
        showNotify("Registro editado com sucesso.")
        handlerClose()
    }).catch(()=>{
        handlerClose()
        showNotify("Um erro ocorreu...",'error')
    })
    }
  }

  return (
    <div>
      <Snack open={handlerSnack} message={msgSnack} severity={sevSnack} handlerSnack={()=>setHandlerSnack(false)}/>
      <Dialog open={props.open} fullWidth={true}
        maxWidth='sm'>
        <Box component="form" onSubmit={handleSubmit}  sx={{ mt: 1 }}>
        <DialogTitle>
            {!props.id ? 'Criar Acesso' : "Editar Acesso"}
        </DialogTitle>
        <DialogContent>
       
          <TextField
          required
          size="small"
            margin="dense"
            id="description"
            name="description"
            label="Descrição"
            type="text"
            fullWidth
           value={state.description}
           onChange={handleChange}
            variant="outlined"
            />
          <TextField
          
          size="small"
            margin="dense"
            id="host"
            name="host"
            label="Host"
            type="text"
            fullWidth
           value={state.host}
           onChange={handleChange}
            variant="outlined"
            />
          <TextField
          
          size="small"
            margin="dense"
            id="username"
            name="username"
            label="Usuário"
            type="text"
            fullWidth
           value={state.username}
           onChange={handleChange}
            variant="outlined"
            />
          <TextField
          
          size="small"
            margin="dense"
            id="password"
            name="password"
            label="Senha"
            type="text"
            fullWidth
           value={state.password}
           onChange={handleChange}
            variant="outlined"
            />
          <TextField
          required
          size="small"
            margin="dense"
            id="porta"
            name="porta"
            label="Portas(ex:22,23,8291)"
            type="text"
            fullWidth
           value={state.porta}
           onChange={handleChange}
            variant="outlined"
            />
          <TextField
          size="small"
          rows={5}
          multiline
            margin="dense"
            id="observacao"
            name="observacao"
            label="Observação"
            type="text"
            fullWidth
           value={state.observacao}
           onChange={handleChange}
            variant="outlined"
            />

        </DialogContent>
        <DialogActions>
          <Button sx={{color: theme.palette.text.primary}} onClick={props.handlerClose}>Cancelar</Button>
          <Button sx={{color: theme.palette.text.primary}} type='submit'> {!props.id ? 'Adicionar' : "Editar"}</Button>
        </DialogActions>
          </Box>
      </Dialog>
    </div>
  );
}

const Snack = (props)=>{
  return  (
    <Snackbar
    open={props.open}
    autoHideDuration={6000}
    onClose={() => {props.handlerSnack()}}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }} 
    >
     <Alert  onClose={() => {props.handlerSnack()}} severity={props.severity ? props.severity : 'error'} sx={{ width: '100%' }}>
    {props.message}
  </Alert>
  </Snackbar>
  )
}