



import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import theme from '../../../theme';
import { Alert, Autocomplete, Box, Checkbox, FormControl, FormControlLabel, FormGroup, Menu, MenuItem, Select, Snackbar } from '@mui/material';
import api from '../../../services/api';


export const module = '/empresa/servico_contratado'
export default function FormDialog(props) {
  const [handlerSnack, setHandlerSnack] = React.useState()
  const [servicos, setServicos] = React.useState([])
 
  
  const item = {
    empresa_id: props.empresa_id,
    servico_id: '',

  }
  const getServicos=()=>{
   
    api.get(`/empresa/servico/all`).then(res=>{setServicos (res.data)
    })
    .catch(err=>console.log(err))
  }
 
  const [state, setState] = React.useState(item);
  
  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  React.useEffect(()=>{
    if (!props.open ) return
    
    getServicos()
   
  
  },[props.open, handlerSnack])



  const showNotify = (msg,sev="success")=>{
    setMsgSnack(msg)
    setSevSnack(sev)
    setHandlerSnack(true)
  }
  
  const [ msgSnack, setMsgSnack ] = React.useState('')
  const [ sevSnack, setSevSnack ] = React.useState('')
  const handleSubmit = (event)=>{
    const updatePerm = (id, cb)=>{
      const data = servicos?.map(perm=>({ 'id': perm.id, 'nome' :perm.nome}))
      api.put(`/empresa/servico_contratado/edit/${id}/`,data).then(res=>{
        cb()
      })
    }
    const handlerClose = ()=>{
      props.handlerClose()
    }
    event.preventDefault()

    if(!props.id){
      api.post(`${module}/create`, state)
      .then(()=>{
          showNotify("Registro adicionado com sucesso.")
          handlerClose()
      }).catch(()=>{
          showNotify("Um erro ocorreu...",'error')
      })
    }else{
      api.put(`${module}/edit/${props.id}/`, state)
      .then(()=>{
        updatePerm(props.id,()=>{
          showNotify("Registro editado com sucesso.")
          handlerClose()
        })
    }).catch(()=>{
        handlerClose()
        showNotify("Um erro ocorreu...",'error')
    })
    }
  }

  return (
    <div>
      <Snack open={handlerSnack} message={msgSnack} severity={sevSnack} handlerSnack={()=>setHandlerSnack(false)}/>
      <Dialog open={props.open} fullWidth={true}
        maxWidth='sm'>
        <Box component="form" onSubmit={handleSubmit}  sx={{ mt: 1 }}>
        <DialogTitle>
         Adicionar Serviços
        </DialogTitle>
        <DialogContent>
        <FormControl fullWidth>

        <Select
        required
        size="small"
        labelId="Serviço"
        id="servico_id"
        name='servico_id'
        value={state.servico_id}
        label="Serviço"
        onChange={handleChange}
  >

        {servicos? servicos.map((servico,i)=>
          <MenuItem key={servico.id} value={servico.id}>{servico.nome}</MenuItem>
          ): ''}
  </Select>

        </FormControl>
        <TextField
                
                multiline
                rows={8}
                size="small"
                margin="dense"
                id="observacao"
                name="observacao"
                label="Observações"
                type="text"
                fullWidth
                value={state.observacao}
                onChange={handleChange}
                variant="outlined"
            />
        </DialogContent>
        <DialogActions>
          <Button sx={{color: theme.palette.text.primary}} onClick={props.handlerClose}>Cancelar</Button>
          <Button sx={{color: theme.palette.text.primary}} type='submit'> {!props.id ? 'Adicionar' : "Editar"}</Button>
        </DialogActions>
          </Box>
      </Dialog>
    </div>
  );
}

const Snack = (props)=>{
  return  (
    <Snackbar
    open={props.open}
    autoHideDuration={6000}
    onClose={() => {props.handlerSnack()}}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }} 
    >
     <Alert  onClose={() => {props.handlerSnack()}} severity={props.severity ? props.severity : 'error'} sx={{ width: '100%' }}>
    {props.message}
  </Alert>
  </Snackbar>
  )
}