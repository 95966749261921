import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  components: {
      MUIDataTableBodyCell: {
        styleOverrides:{
          root: {
              color: "#000000"
          }
        }
      }
    },
  
  palette: {
     mode: localStorage.theme == 'dark' || localStorage.theme ==  undefined ? 'dark': 'light'
     
    // primary: {
    //   dark: '#8D0B26',
    //   main:'#c4161c',
    //   //contrastText:
    // },
    // secondary: {
    //   dark: '#E2FBD7',
    //   main:'#0A5B29'
    // },
    // info: {
    //   dark: '#C9F0FB',
    //   main:'#001E59'
    // },
    // warning: {
    //   dark: '#FBEFCA',
    //   main:'#5B2600'
    // },
    // error: {
    //   dark: '#FCE0D1',
    //   main:'#610627'
    // },
  
  },
});

export default theme;
