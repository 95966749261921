import { BorderColor, ConstructionOutlined, DnsOutlined, Egg } from '@mui/icons-material';
import { Box } from '@mui/material';
import React, { memo } from 'react';
import ReactFlow, { EdgeText, MiniMap, Controls, getEdgeCenter, addEdge, applyEdgeChanges, applyNodeChanges, Handle, Position, Background, useReactFlow, ReactFlowProvider } from 'react-flow-renderer';
import CustomNode from './CustomNode';
import { green, grey, red } from '@mui/material/colors';
import theme from '../theme'
import { renderItem, getItemTitle, getItemZabbix } from './items';
import api from '../services/api';
import Graph from './graph'
import GraphNode from './GraphNode';

const nodeTypes = {
  custom: CustomNode,
  graph: GraphNode
};

function Flow({ map, hosts, contextMenu, cod_empresa, host, problems, items }) {

  if (map == false || hosts == false) {
    return (<></>)
  }
  const reactFlowRef = React.useRef(null);



  const mapWithHost = map.selements.map(el => ({ ...el, elements: el.elements.length > 0 ? hosts.filter(host => parseInt(host[1]) == parseInt(el.elements[0].hostid)) : [] }))
  const { setCenter } = useReactFlow();
  React.useLayoutEffect(() => {
    if (host == null || mapWithHost === undefined || items == false) return
    host = mapWithHost?.filter(node => node.elements.length > 0).filter(el => el.elements[0][1] == host[1])
    const { x, y } = host[0]
    setCenter(parseInt(x), parseInt(y), { duration: 1000, zoom: 1.5 })

  })
  // Mapeia os nós do mapa para o formato aceito pelo React Flow
  const nodes_raw = mapWithHost.map((node) => (
    {
      id: node.selementid,
      elements : node.elements,
      type: 'custom',
      position: { x: Number(node.x), y: Number(node.y) },
      elements : node.elements,
      data: {
        label: node.elements.length > 0 ? node.elements[0][2] : node.label || '',
        ip: node.elements.length > 0 ? node.elements[0][3] : '',
        model: node.elements.length > 0 ? node.elements[0][4] : '',
        contextMenu: contextMenu,
        cod_empresa: cod_empresa,
        problems: node.elements.length > 0 ? problems.filter(p => p.hostid == node.elements[0][1]) : []
      },
    }));

  // Mapeia os links do mapa para o formato aceito pelo React Flow
  const links = map.links.map((link) => {

    return {
      id: link.linkid,
      style: {
        strokeWidth: 2,
        stroke: `#${link.color}`,
      },
      source: link.selementid1,
      target: link.selementid2,
      type: 'straight',
      rawLabel: link.label,
      smooth: false,
      labelShowBg: true,
      labelStyle: {
        fill: theme.palette.mode == 'light' ? grey[900] : grey[100], // Cor do texto da label
      },
      labelBgStyle: {
        fill: theme.palette.mode == 'dark' ? grey[900] : grey[100],
        stroke: `#${link.color}`, // Cor da borda do fundo do texto
        strokeWidth: 2, // Espessura da borda do fundo do texto
        borderRadius: 4, // Raio de borda do fundo do texto
      },
      labelBgPadding: [6, 4], // Preenchimento interno do fundo do texto
      labelBgBorderRadius: 4, // Raio de borda do fundo do texto
    }
  });
  const [nodes, setNodes] = React.useState(nodes_raw);
  const [edges, setEdges] = React.useState(links);


  React.useEffect(() => {
    setEdges((prevEdges) =>
      prevEdges.map((edge) => ({
        ...edge,
        label: renderItem(edge.rawLabel, items),
        itemid: getItemZabbix(edge.rawLabel, items),
        title: getItemTitle(edge.rawLabel, items)
      }))
    );
  }, [items]);

  React.useEffect(() => {
    setNodes((prevNodes) =>
      prevNodes.map((node) => {
        if (!node.elements) {
          return node; // Retorna o próprio nó sem alteração se não houver elementos
        }
  
        return {
          ...node,
          data: {
            ...node.data,
            problems:  node.elements.length > 0 ? problems.filter(p => p.hostid == node.elements[0][1]) : []
          },
        };
      })
    );
  }, [problems]);
  
  const onNodesChange = React.useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    [setNodes]
  );
  const onEdgesChange = React.useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    [setEdges]
  );
  const onConnect = React.useCallback(
    (connection) => setEdges((eds) => addEdge(connection, eds)),
    [setEdges]
  );



  return (

    <div style={{ height: `92vh`, width: '100%' }} ref={reactFlowRef}>

      <ReactFlow nodes={nodes} edges={edges}
        minZoom={0.1}
        maxZoom={6}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        fitView
        nodeTypes={nodeTypes}
        snapToGrid={true}
        onClick={(event)=>console.log(event)}
        onEdgeClick={(event, edge) => {
          if (!edge.itemid) return
          console.log(event)
          const x  =event.nativeEvent.offsetX
          const y = event.nativeEvent.offsetY
          const id = edge.itemid[0]
          const newNode = {
            id: id,
            type: 'graph',
            
            data: {
              itemid: edge.itemid, cod_empresa: cod_empresa, title: edge.title,

              close: () => {
                setNodes((prevNodes) => prevNodes.filter((node) => node.id !== id))
              }

            },
            position: { x:x,y:y},
          };
          setNodes((prevNodes) => [...prevNodes, newNode]);
         // setCenter( x,y, { duration: 1000, zoom: 1 })



        }}
      >

        <Background gap={12} size={0.5} />
      </ReactFlow>
    </div>
  );
}

function ZabbixMap(props) {
  return (
    <ReactFlowProvider>
      <Flow {...props} />

    </ReactFlowProvider>
  );
}

export default memo(ZabbixMap)