import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MainListItems from './listItems';
import { red,indigo, grey } from '@mui/material/colors';
import LogoutIcon from '@mui/icons-material/Logout';
import { Outlet, useNavigate } from 'react-router-dom';
import theme from '../theme';
import themeDash from '../themeDash';
import { useAuth } from '../auth/AuthProvider';
import { Switch } from '@mui/material';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import ListContextMenu from '../Map/MenuContext';
import Monitor from '../Monitor'
import Painel from '../alarms/Painel';
import logo from './logo.png'

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);


function DashboardContent() {
  const auth = useAuth()
  
  const navigate = useNavigate()
  const [open, setOpen] = React.useState(JSON.parse(localStorage.getItem('MenuState')) || false);
  const [themeMode, setThemeMode] = React.useState(localStorage.theme == 'dark' || localStorage.theme ==  undefined ? true: false)
  const toggleDrawer = () => {
    setOpen(!open);
    localStorage.MenuState = !open
  };

  return (
    <ThemeProvider theme={theme}>
      <ListContextMenu />
      <Box sx={{ display: 'flex',
    }}>
        <CssBaseline />
        <AppBar  open={open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
              height: '75px',
              
              backgroundColor: theme.palette.mode =='dark' ? '' : grey[800]
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <KeyboardDoubleArrowRightIcon />
            </IconButton>
            
              
              <Painel/>
              {auth.name}
            <Switch
              checked={themeMode}
              onChange={()=>{
                setThemeMode(!themeMode)
                const t = !themeMode ? "dark": 'light'
                localStorage.setItem('theme',t)

                theme.palette.mode =localStorage.theme
                themeDash.palette.mode =localStorage.theme
                location.reload()
              }}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            <Monitor></Monitor>
            <IconButton   onClick={() => {
          auth.signout(() => console.log("Saindo...."));
        }} color="inherit">    
                <LogoutIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}
        >
          <Box
          backgroundColor={grey[900]}
          
            justifyContent={"space-between"}
            alignItems={"center"}
            display={"flex"}
          >
          <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          width={"100%"}
          height={"75px"}
         
          >

          <img src={logo}
              
              style={{
                width: 100,
               
                
                
                
              }}
              />
          </Box>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            
            <IconButton onClick={toggleDrawer}
           
            >
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
              </Box>
          <Divider />
          <MainListItems/>
        </Drawer>
        <Box
          component="main"
          sx={{
        
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            minHeight: '100vh',
            overflow: 'hidden'
           
          }}
        >
          <Toolbar />
              <Outlet/>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default function Dashboard() {
  return <DashboardContent />;
}
