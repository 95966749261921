import { Box, ThemeProvider } from '@mui/material';
import React, { Component }  from 'react';

import { useRouteError } from "react-router-dom";
import theme from './themeDash';
import { Container } from '@mui/material';


export default function NotAuth() {
 
  return (
    <Container maxWidth={'sm'} sx={{ mt: 4, mb: 4 }}>
    <ThemeProvider theme={theme}>
        
        <Box sm={10} lg={8}>
            
          <h1>Oops!</h1>
          <p>Você não tem permissão para acessar este conteúdo.</p>
          <p>
          
          </p>
           
        </Box>
    </ThemeProvider>
</Container>

      
   
  );
}