import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import theme from '../theme';
import { Alert, Box, Checkbox, FormControl, FormControlLabel, FormGroup, IconButton, Input, InputLabel, List, ListItem, ListItemButton, ListItemText, MenuItem, Select, Snackbar } from '@mui/material';
import api from '../services/api';

import { grey } from '@mui/material/colors';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ClearIcon from '@mui/icons-material/Clear';

export default function FormDialog(props) {
  const [handlerSnack, setHandlerSnack] = React.useState()
  
  const item = {
    cod_empresa: '',
    times: [],
    day_week: 'false',
    start_hour: 'false',
    end_hour: 'false'

  }
  const day_week = [
    { id: 'false', description: "" },
    { id: 7, description: "Domingo" },
    { id: 1, description: "Segunda" },
    { id: 2, description: "Terça" },
    { id: 3, description: "Quarta" },
    { id: 4, description: "Quinta" },
    { id: 5, description: "Sexta" },
    { id: 6, description: "Sábado" },
  ]
  const hours = [

    { id: 'false', description: "" },
    { id: -1, description: "Não monitorar" },
    { id: 0, description: "00:00" },
    { id: 1, description: "01:00" },
    { id: 2, description: "02:00" },
    { id: 3, description: "03:00" },
    { id: 4, description: "04:00" },
    { id: 5, description: "05:00" },
    { id: 6, description: "06:00" },
    { id: 7, description: "07:00" },
    { id: 8, description: "08:00" },
    { id: 9, description: "09:00" },
    { id: 10, description: "10:00" },
    { id: 11, description: "11:00" },
    { id: 12, description: "12:00" },
    { id: 13, description: "13:00" },
    { id: 14, description: "14:00" },
    { id: 15, description: "15:00" },
    { id: 16, description: "16:00" },
    { id: 17, description: "17:00" },
    { id: 18, description: "18:00" },
    { id: 19, description: "19:00" },
    { id: 20, description: "20:00" },
    { id: 21, description: "21:00" },
    { id: 22, description: "22:00" },
    { id: 23, description: "23:00" },
  ]
  const hours_end = [

    { id: 'false', description: "" },
    { id: -1, description: "Não monitorar" },
    { id: 0, description: "00:59" },
    { id: 1, description: "01:59" },
    { id: 2, description: "02:59" },
    { id: 3, description: "03:59" },
    { id: 4, description: "04:59" },
    { id: 5, description: "05:59" },
    { id: 6, description: "06:59" },
    { id: 7, description: "07:59" },
    { id: 8, description: "08:59" },
    { id: 9, description: "09:59" },
    { id: 10, description: "10:59" },
    { id: 11, description: "11:59" },
    { id: 12, description: "12:59" },
    { id: 13, description: "13:59" },
    { id: 14, description: "14:59" },
    { id: 15, description: "15:59" },
    { id: 16, description: "16:59" },
    { id: 17, description: "17:59" },
    { id: 18, description: "18:59" },
    { id: 19, description: "19:59" },
    { id: 20, description: "20:59" },
    { id: 21, description: "21:59" },
    { id: 22, description: "22:59" },
    { id: 23, description: "23:59" },
  ]
  const [state, setState] = React.useState(item);
  const empresa = props?.data?.filter(filterEmpresa)
  function filterEmpresa(empresa){
    return empresa.id==props.id
  }
  const handleChange = (event) => {
    if (event.target.name == "start_hour") {
      setState({
        ...state,
        [event.target.name]: event.target.value,
        end_hour: event.target.value
      });
      return
    }
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };
  const handleChangeCheck = (event) => {
    console.log(event.target.checked)
    setState({
      ...state,
      [event.target.name]: event.target.checked
    });
  };

  React.useEffect(() => {
    getEditInfo()
  }, [props.open, handlerSnack, ])


  const getEditInfo = () => {
    setState(item)
    if(!props.id && !props.open) return
    api.get(`api_zabbix/all-time/${props.id}`)
      .then(res => {
        setState(s => ({ ...s, times: res.data }))

      }).catch(err => console.log(err))
    
  }
  const showNotify = (msg, sev = "success") => {
    setMsgSnack(msg)
    setSevSnack(sev)
    setHandlerSnack(true)
  }

  const [msgSnack, setMsgSnack] = React.useState('')
  const [sevSnack, setSevSnack] = React.useState('')
  const handleSubmit = (event) => {
    event.preventDefault()
    const handlerClose = () => {
      props.handlerClose()
    }
    console.log(state.times)
    api.put(`api_zabbix/edit-time/${props.id}/`, 
      {items: state.times}
    )
      .then(() => {
        showNotify("Registro editado com sucesso.")
        handlerClose()
      }).catch((err) => {
        console.log(err)
        handlerClose()
        showNotify("Um erro ocorreu...", 'error')
      })

  }
  const handleAddItem = () => {
  
    if (state.day_week == 'false' || state.start_hour == 'false' || state.end_hour == 'false') return
    setState(s => ({
      day_week: 'false', start_hour: 'false', end_hour: 'false',
      times: [...s.times, {
        day_week: s.day_week,
        start_hour: s.start_hour,
        end_hour: s.end_hour,
        cod_empresa: props.id
      }
      ]
    }))
    
  }


  return (
    <div>
      <Snack open={handlerSnack} message={msgSnack} severity={sevSnack} handlerSnack={() => setHandlerSnack(false)} />
      <Dialog open={props.open} fullWidth={true}
        maxWidth='sm'>
        <DialogTitle>
         Gerenciamento de Horário da API: {empresa != undefined  ? empresa[0]?.cod_empresa: ''}
        </DialogTitle>
        <Box component="form" onSubmit={handleSubmit}>
          <DialogContent>
            <Box display={"flex"}  sx={{ mt: 1 }}>

              <FormControl fullWidth >
                <InputLabel id="dayweek-label">Dia da Semana</InputLabel>
                <Select
                  required
                  size="small"
                  labelId="dayweek-label"
                  id="dayweek"
                  placeholder='Segunda'
                  name='day_week'
                  value={state.day_week}
                  label="Dia da Semana"
                  onChange={handleChange}
                >

                  {day_week ? day_week?.map((day,) =>
                    <MenuItem key={"s"+day?.id} value={day?.id}>{day?.description}</MenuItem>
                  ) : ''}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="dayweek-label">Hora Inicial</InputLabel>
                <Select
                  required
                  size="small"
                  labelId="Hora Inicial"
                  id="role"
                  name='start_hour'
                  value={state.start_hour}
                  label="Hora Inicial"
                  onChange={handleChange}
                >

                  {hours ? hours.map((day,) =>
                    <MenuItem key={"h"+day.id} value={day.id}>{day.description}</MenuItem>
                  ) : ''}
                </Select>
              </FormControl >
              <FormControl fullWidth>
                <InputLabel id="dayweek-label">Hora Final</InputLabel>
                <Select
                  required
                  size="small"
                  labelId="Hora Final"
                  id="role"
                  name='end_hour'
                  value={state.end_hour}
                  label="Hora Final"
                  onChange={handleChange}
                >

                  {hours_end ? hours_end.filter(h => state.start_hour ? h.id >= state.start_hour: h.id != 999).map((day,) =>
                    <MenuItem key={day.id} value={day.id}>{day.description}</MenuItem>
                  ) : ''}
                </Select>
              </FormControl>
              <Box>

                <IconButton
                  sx={{
                    ml: 1
                  }} onClick={handleAddItem}
                >
                  <AddCircleIcon />
                </IconButton>

              </Box>
            </Box>
            <List>
              {state.times.map((t, i) =>
                <>
                  <ListItem key={"l"+i} sx={{
                    backgroundColor: grey[900],
                    padding: .5,
                    pl: 2
                  }}>
                    <ListItemText key={888-i}
                      primary={`${day_week.filter(d => d.id == t.day_week)[0].description} - ${hours.filter(d => d.id == t.start_hour)[0].description} - ${hours_end.filter(d => d.id == t.end_hour)[0].description}`}
                    />
                    <IconButton key={999-i} onClick={() => setState(s => ({ ...s, times: s.times.filter((t, j) => j != i) }))}>
                      <ClearIcon></ClearIcon>
                    </IconButton>
                  </ListItem>
                </>
              )}

            </List>
          </DialogContent>
          <DialogActions>
            <Button sx={{ color: theme.palette.text.primary }} onClick={() => { setState(item); props.handlerClose(); }}>Cancelar</Button>
            <Button sx={{ color: theme.palette.text.primary }} type='submit'> {!props.id ? 'Adicionar' : "Editar"}</Button>
          </DialogActions>
        </Box>
      </Dialog>
    </div>
  );
}

const Snack = (props) => {
  return (
    <Snackbar
      open={props.open}
      autoHideDuration={6000}
      onClose={() => { props.handlerSnack() }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      <Alert onClose={() => { props.handlerSnack() }} severity={props.severity ? props.severity : 'error'} sx={{ width: '100%' }}>
        {props.message}
      </Alert>
    </Snackbar>
  )
}