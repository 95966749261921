import { ThemeProvider } from '@emotion/react'
import { Backdrop, Box, Button, CircularProgress, Container, IconButton } from '@mui/material'

import React, { useState, useEffect, useLayoutEffect } from 'react'
import api from '../../../services/api'
import theme from '../../../themeDash'


import EmpresaSelect from '../EmpresaSelect'
import DateTimeToolbar from '../../DateTimeToolbar'
import moment from 'moment'
import Graph from './graph'


export default function () {

  const [empresas, setEmpresas] = useState([])
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])

  useLayoutEffect(() => {
    document.title = "Relatórios de Alarmes - Dashboard NIIS IPV7"
  })


    const fetchData =  () => {
      setLoading(true)
       api.post('/report/alarmes/host',{
        empresas: empresas,
        start: startDate,
        end: endDate
       }).then(
        resp=>{
          setLoading(false)
          setData(resp.data)
        }
       ).finally(()=>setLoading(false))
    }
  
  return (


    <Container sx={{ mt: 4, mb: 4 }} maxWidth >
      <ThemeProvider theme={theme}>
        <Box sm={12} lg={12}
        >
        <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        
      >
        <CircularProgress color="inherit" />
      </Backdrop>
          <Box
              display="flex"
              sx={{ bgcolor: 'background.paper', p: 1,
                boxShadow: 1,
              
            }}
            >
              <EmpresaSelect handler={setEmpresas} />
              <DateTimeToolbar label={'Data Inicial'} handler={setStartDate} initValue={moment().subtract(1, 'days')} />
              <DateTimeToolbar label={'Data Final'} handler={setEndDate} />
              <Button onClick={() => fetchData()} disabled={
                !(startDate) || !(endDate) || !(empresas.length > 0) || loading
              }
                variant="contained" color="primary" sx={{ m: 1 }}>Buscar</Button>
          </Box>
              <Graph data={data}/>
 
        </Box>
      </ThemeProvider>
    </Container>

  )
}