import React, { useState } from 'react';
import { Box, IconButton } from '@mui/material';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { Close, Pin } from '@mui/icons-material';
import api from '../services/api';
import moment from 'moment';

const convertUnixToDateTime = (unixTimestamp) => moment.unix(unixTimestamp).format('YYYY-MM-DD HH:mm:ss');
function transformData(inputArray) {
  const transformedData = inputArray.reduce((result, item, index, array) => {
      const { clock, itemid, value } = item;
      if (!result[clock]) {
          result[clock] = { clock, [itemid]: value };
      } else {
          result[clock][itemid] = value;
      }
      return result;
  }, {});

  return Object.values(transformedData);
}


const ChartBox = ({ itemid, display,cod_empresa }) => {
    if(!display) return('')
    const [data, setData] = useState([]);
    
    React.useEffect(() => {
      api.post('map/items-data',{
        cod_empresa: cod_empresa,
        itemid: itemid
      }).then(res=> {
        const data = res.data.map(item=>({...item, value: Number((Number(item.value)/1000000000).toFixed(2)), clock: convertUnixToDateTime(Number(item.clock))}))
        console.log(transformData(data))
        setData(data)
      
      })
  
    }, [itemid,display]);

  const [isFixed, setIsFixed] = useState(false);

  const handleFixToggle = () => {
    setIsFixed(!isFixed);
  };

  return (
    <Box
      sx={{
        position: isFixed ? 'fixed' : 'relative',
        top: isFixed ? '20px' : 'auto',
        right: isFixed ? '20px' : 'auto',
        zIndex: 999,
        width: '400px',
        height: '300px',
        backgroundColor: '#fff',
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.2)',
        borderRadius: '4px',
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <IconButton onClick={handleFixToggle}>
          {isFixed ? <Close /> : <Pin />}
        </IconButton>
      </Box>

      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          width={500}
          height={400}
          data={data}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="itemid" />
          <YAxis />
          <Tooltip />
                      
          <Area type="monotone" dataKey="value" stackId="1" stroke="#8884d8" fill="#8884d8" />
        
          
        </AreaChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default ChartBox;


















