import { ThemeProvider } from '@emotion/react'
import { Box, Button, Container, IconButton } from '@mui/material'
import MUIDataTable from 'mui-datatables'
import React,{useState, useEffect} from 'react'
import theme from '../../theme'
import api from '../../services/api'


import { useAuth } from '../../auth/AuthProvider'
import { redirect, useNavigate } from 'react-router-dom'
import EmpresaSelect from '../EmpresaSelect'
import UserSelect from '../UserSelect'
import DateTimeToolbar from '../DateTimeToolbar'
import moment from 'moment'
import { textLabels } from '../textLabelsPT'

export default function () {
    const navigate = useNavigate()
    const auth = useAuth()
    const [handlerDialog, setHandlerDialog] = useState(false)
    const [update, setUpdate] = useState(false)
    const [id, setId] = useState()
    const [ startDate,setStartDate] = useState(null)
    const [ endDate,setEndDate] = useState(null)
    const [user,setUser] = useState()
    useEffect(()=>{
        document.title = "Relatórios de Auditoria- Dashboard NIIS IPV7"
    })
    useEffect(() => {
        setUpdate(false)
        updateData()
        

    }, [handlerDialog, update])

    useEffect(()=>{
        updateData(0, data.limit)
    },[startDate,endDate, user])
  
    const [data, setData] = useState({ results: [], total: 0, limit:30 })
    const columns = [
        {
            name: 'username',
            label: "Nome",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: 'description',
            label: "Ação:",
            options: { 
                filter: false,
                sort: false,
                
            }
        },
        {
            name: 'created_at',
            label: "Quando",
            options: {
                filter: false,
                sort: false,
                customBodyRender: value => <span>{moment(value).locale('pt-br').format('DD-MM-YYYY HH:mm:ss')} ({moment(value).locale('pt-br').fromNow()})</span>,
            }
        },
        
        
    ]

    const updateData = (page=0, limit=30) => {
        const skip = page*limit
        
        api.post('report/audit',{ 
            page, skip, limit,
            start:startDate,
            end:endDate,
            user: user

        })
            .then(res => {
                
               
                setData(data => ({...data, results: res.data.results, total: res.data.total, limit }))})
            .catch((err) => {
                console.log(err)    
                if(err.response.status==401){
                  navigate('/401')
                }
                setData({ results: [], total: [], limit:30 })
              })
    }

    const showFormDialog = () => {
        setId()
        setHandlerDialog(true)
    }
    const options = {
        search: false,
        textLabels: textLabels,
        filterType: 'checkbox',
        rowsPerPageOptions: [30, 50, 100, 500,1000,2000],
        rowsPerPage: data.limit,
        count: data.total,
        rowHover: true,
        print: true,
        selectableRows: 'none',
        download: true,
        //customToolbar,
        customToolbarSelect: () => { },
        serverSide: true,
        onTableChange: (action, tableState) => {
            const {page,rowsPerPage } = tableState
            switch (action) {
              case 'changePage':
                updateData(page)
                break;
              case 'changeRowsPerPage':
                updateData(page, rowsPerPage)
                break;
              default:
               // console.log('action not handled.');
            }
          },
    }
    return (
        <Container  sx={{ mt: 4, mb: 4 }}>
            <ThemeProvider theme={theme}>
               
                <Box sm={12} lg={12}>
                    <MUIDataTable
                        title={<>
                            <UserSelect handler={setUser}/>
                            <DateTimeToolbar label={'Data Inicial'} handler={setStartDate} initValue={moment().subtract(1,'days')}/>
                            <DateTimeToolbar label={'Data Final'} handler={setEndDate}/>
                            
                        </>}
                        data={data.results}
                        columns={columns}
                        options={options}
                    />
                </Box>
            </ThemeProvider>
        </Container>
    )
}