import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import theme from '../../theme'
import { Alert, Autocomplete, Box, Checkbox, FormControl, FormControlLabel, FormGroup, Select, Snackbar } from '@mui/material';
import api from '../../services/api';
import { MenuItem } from '@mui/material';



export default function FormDialog(props) {
  const [handlerSnack, setHandlerSnack] = React.useState()
  const [tags, setTags] = React.useState([])
  const item = {
    description: '',
    observacao: '',
    tags: []

  }
  const [state, setState] = React.useState(item);

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };


  React.useEffect(()=>{
    getEditInfo()
    getTags()
  },[props.id, handlerSnack])

  const getTags = ()=> api.get('empresa/controle_horas/tag/all').then(res=> setTags(res.data))
  const getEditInfo = ()=>{
    setState(item)
    if(!props.id) return
    api.get(`empresa/controle_horas/tipo_servico/find/${props.id}`)
    .then(res=>{
      let data = res.data
      
      setState(data)
  

    }).catch(err=> setState(item))
  }
  const showNotify = (msg,sev="success")=>{
    setMsgSnack(msg)
    setSevSnack(sev)
    setHandlerSnack(true)
  }
  
  const [ msgSnack, setMsgSnack ] = React.useState('')
  const [ sevSnack, setSevSnack ] = React.useState('')

  

 
  const handleSubmit = (event)=>{
    const handlerClose = ()=>{
      props.handlerClose()
    }
    event.preventDefault()
    if(!props.id){
      api.post('empresa/controle_horas/tipo_servico/create', state)
      .then(()=>{
          showNotify("Registro adicionado com sucesso.")
          handlerClose()
      }).catch(()=>{
          showNotify("Um erro ocorreu...",'error')
      })
    }else{
      api.put(`empresa/controle_horas/tipo_servico/edit/${props.id}/`, state)
      .then(()=>{
        showNotify("Registro editado com sucesso.")
        handlerClose()
    }).catch((err)=>{
        console.log(err)
        handlerClose()
        showNotify("Um erro ocorreu...",'error')
    })
    }
  }

  return (
    <div>
      <Snack open={handlerSnack} message={msgSnack} severity={sevSnack} handlerSnack={()=>setHandlerSnack(false)}/>
      <Dialog open={props.open} fullWidth={true}
        maxWidth='sm'>
        <Box component="form" onSubmit={handleSubmit}  sx={{ mt: 1 }}>
        <DialogTitle>
            {!props.id ? 'Criar Serviço de Controle de Horas' : "Editar Serviço de Controle de Horas"}
        </DialogTitle>
        <DialogContent>
       
          <TextField
          size="small"
            margin="dense"
            id="description"
            name="description"
            label="Nome do Serviço"
            type="text"
            fullWidth
           value={state.description}
           onChange={handleChange}
            variant="outlined"
            />
             <Autocomplete
              multiple
              size="small"
              id="tags-outlined"
              options={tags.filter((item)=>!state.tags.includes(item.id))}
              onChange={(event, newValue) => {
                setState({
                  ...state,
                  tags: newValue.map((item)=>item?.id)
                })
                  
               
                 
              }}
             
              isOptionEqualToValue={(option, value) => option.id == value}
              getOptionLabel={(option) => option?.description}
              value={tags.filter((item)=>state.tags.includes(item.id))}
              filterSelectedOptions = {true}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Tags"
          />
        )}
      />  
          <TextField
          size="small"
          rows={5}
          multiline
            margin="dense"
            id="observacao"
            name="observacao"
            label="Observação"
            type="text"
            fullWidth
           value={state.observacao}
           onChange={handleChange}
            variant="outlined"
            />

        </DialogContent>
        <DialogActions>
          <Button sx={{color: theme.palette.text.primary}} onClick={props.handlerClose}>Cancelar</Button>
          <Button sx={{color: theme.palette.text.primary}} type='submit'> {!props.id ? 'Adicionar' : "Editar"}</Button>
        </DialogActions>
          </Box>
      </Dialog>
    </div>
  );
}

const Snack = (props)=>{
  return  (
    <Snackbar
    open={props.open}
    autoHideDuration={6000}
    onClose={() => {props.handlerSnack()}}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }} 
    >
     <Alert  onClose={() => {props.handlerSnack()}} severity={props.severity ? props.severity : 'error'} sx={{ width: '100%' }}>
    {props.message}
  </Alert>
  </Snackbar>
  )
}