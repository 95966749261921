import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import theme from '../theme';
import { Alert, Autocomplete, Box, Checkbox, FormControl, FormControlLabel, FormGroup, Select, Snackbar } from '@mui/material';
import api from '../services/api';
import { MenuItem } from '@mui/material';



export default function FormDialog(props) {
  const [handlerSnack, setHandlerSnack] = React.useState()
  
  const item = {
    username: '',
    password: '',
    name: '',
    email: '',
    active: false,
    role:''

  }

  const handleChangeCheck = (event) => {
    setState({
      ...state,
      [event.target.name]:  event.target.checked
    });
  };

  const [state, setState] = React.useState(item);

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };


  React.useEffect(()=>{
    getEditInfo()
    getRoles()
  },[props.id, handlerSnack])


  const getEditInfo = ()=>{
    setState(item)
    if(!props.id) return
    api.get(`user/find/${props.id}`)
    .then(res=>{
      let data = res.data.__data__
      
      setState(data)
  

    }).catch(err=> setState(item))
  }
  const showNotify = (msg,sev="success")=>{
    setMsgSnack(msg)
    setSevSnack(sev)
    setHandlerSnack(true)
  }
  
  const [ msgSnack, setMsgSnack ] = React.useState('')
  const [ sevSnack, setSevSnack ] = React.useState('')
  const [ roles, setRoles ] = React.useState('')
  

  const getRoles = ()=>{
    api.get('role/all').then(res=>{
      setRoles(res.data)
    }).catch(err=>console.log(err))
  }
  const handleSubmit = (event)=>{
    const handlerClose = ()=>{
      props.handlerClose()
    }
    event.preventDefault()
    if(!props.id){
      api.post('user/create', state)
      .then(()=>{
          showNotify("Registro adicionado com sucesso.")
          handlerClose()
      }).catch(()=>{
          showNotify("Um erro ocorreu...",'error')
      })
    }else{
      api.put(`user/edit/${props.id}/`, state)
      .then(()=>{
        showNotify("Registro editado com sucesso.")
        handlerClose()
    }).catch(()=>{
        handlerClose()
        showNotify("Um erro ocorreu...",'error')
    })
    }
  }

  return (
    <div>
      <Snack open={handlerSnack} message={msgSnack} severity={sevSnack} handlerSnack={()=>setHandlerSnack(false)}/>
      <Dialog open={props.open} fullWidth={true}
        maxWidth='sm'>
        <Box component="form" onSubmit={handleSubmit}  sx={{ mt: 1 }}>
        <DialogTitle>
            {!props.id ? 'Criar Usuário' : "Editar Usuário"}
        </DialogTitle>
        <DialogContent>
        <FormControl fullWidth>
        <Select
        required
        size="small"
      labelId="Cargo"
        id="role"
        name='role'
        value={state.role}
        label="Cargo"
        onChange={handleChange}
  >

        {roles? roles.map((role,i)=>
          <MenuItem key={role.id} value={role.id}>{role.description}</MenuItem>
          ): ''}
  </Select>
       
  </FormControl>
          <TextField
          required
          size="small"
            margin="dense"
            id="name"
            name="name"
            label="Nome"
            type="text"
            fullWidth
           value={state.name}
           onChange={handleChange}
            variant="outlined"
            />
          <TextField
          required
          size="small"
            margin="dense"
            id="username"
            name="username"
            label="Usuário"
            type="text"
            fullWidth
           value={state.username}
           onChange={handleChange}
            variant="outlined"
            />
             <TextField
          required
          size="small"
            margin="dense"
            id="email"
            name="email"
            label="Email"
            type="email"
            fullWidth
           value={state.email}
           onChange={handleChange}
            variant="outlined"
            />
          <TextField
          required = {props.id? false: true}
          size="small"
            margin="dense"
            id="password"
            name="password"
            label="Senha"
            type="text"
            fullWidth
           value={state.password}
           onChange={handleChange}
            variant="outlined"
            />
            <FormGroup>

<FormControlLabel sx={{ ml:1, mt:1}}
  control={
    <Checkbox checked={state.active} onChange={handleChangeCheck} name="active"
    />
  }
  label="Ativo"
  />
  </FormGroup>

        </DialogContent>
        <DialogActions>
          <Button sx={{color: theme.palette.text.primary}} onClick={props.handlerClose}>Cancelar</Button>
          <Button sx={{color: theme.palette.text.primary}} type='submit'> {!props.id ? 'Adicionar' : "Editar"}</Button>
        </DialogActions>
          </Box>
      </Dialog>
    </div>
  );
}

const Snack = (props)=>{
  return  (
    <Snackbar
    open={props.open}
    autoHideDuration={6000}
    onClose={() => {props.handlerSnack()}}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }} 
    >
     <Alert  onClose={() => {props.handlerSnack()}} severity={props.severity ? props.severity : 'error'} sx={{ width: '100%' }}>
    {props.message}
  </Alert>
  </Snackbar>
  )
}