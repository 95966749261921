export const textLabels= {
    body: {
      noMatch: 'Nenhum registro encontrado',
      toolTip: 'Classificar',
      columnHeaderTooltip: column => `Classificar por ${column.label}`,
    },
    pagination: {
      next: 'Próxima Página',
      previous: 'Página Anterior',
      rowsPerPage: 'Linhas por página:',
      displayRows: 'de',
    },
    toolbar: {
      search: 'Pesquisar',
      downloadCsv: 'Download CSV',
      print: 'Imprimir',
      viewColumns: 'Ver Colunas',
      filterTable: 'Filtrar Tabela',
    },
    filter: {
      all: 'TODOS',
      title: 'Filtros',
      reset: 'Limpar',
    },
    viewColumns: {
      title: 'Mostrar Colunas',
      titleAria: 'Mostrar/Ocultar Colunas da Tabela',
    },
    selectedRows: {
      text: 'linha(s) selecionada(s)',
      delete: 'Excluir',
      deleteAria: 'Excluir Linhas Selecionadas',
    },
  }