import { Collapse, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import React from 'react';
import { useAuth } from '../auth/AuthProvider';
import { Link } from 'react-router-dom';
import theme from '../theme';
import { grey } from '@mui/material/colors';
import { ExpandLess, ExpandMore, Warning } from '@mui/icons-material';

import ArticleIcon from '@mui/icons-material/Article';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
const handlerLoc = (item) => JSON.parse(localStorage.getItem(item)) || false
export default function ListMenu(props) {
const [open, setOpen] = React.useState({
    ppiMenu: handlerLoc('ppiMenu')
  });

const handleClick = (item, value) => {
    setOpen(open => ({ ...open, [item]: !value }))
    localStorage.setItem(item, !value)

  };

    const auth = useAuth();
    return (
        <>
   {auth.hasPerm('user.get') ?
        <>
          <ListItemButton onClick={() => handleClick('ppiMenu', open.ppiMenu)}
            sx={{ 
            backgroundColor: open?.ppiMenu ?  theme.palette.mode=='dark' ? grey[900] : grey[100]: theme.palette.mode=='dark' ? grey[900] : grey[100],
            color: theme.palette.mode=='dark' ? grey[100] : grey[900]
            }}>
            <ListItemIcon>
              <ArticleIcon />
            </ListItemIcon>
            <ListItemText primary="PPIS"
             />
            {open.ppiMenu? <ExpandLess /> : <ExpandMore />}
          </ListItemButton >
          <Collapse in={open?.ppiMenu} timeout="auto" unmountOnExit>
            <List component="div" disablePadding sx={{ 
                pl:1,
                backgroundColor: theme.palette.mode=='dark' ? grey[900] : grey[100],
          color: theme.palette.mode=='dark' ? grey[200] : grey[900]
             }} >
              <Link to={'/ppi'} style={{ textDecoration: 'none', 
          backgroundColor: theme.palette.mode=='dark' ? grey[900] : grey[100],
          color: theme.palette.mode=='dark' ? grey[100] : grey[900]
        
        }}>
                <ListItemButton  >
                  <ListItemIcon>
                    <ArticleIcon />
                  </ListItemIcon>
                  <ListItemText primary="PPIS" />
                </ListItemButton>
              </Link>
              <Link to={'/ppi/group'} style={{ textDecoration: 'none', 
          backgroundColor: theme.palette.mode=='dark' ? grey[900] : grey[100],
          color: theme.palette.mode=='dark' ? grey[100] : grey[900]
        
        }}>

                <ListItemButton  >
                  <ListItemIcon>
                    <AutoAwesomeMotionIcon />
                  </ListItemIcon>
                  <ListItemText primary="Grupos" />
                </ListItemButton>
              </Link>
            
            </List>

          </Collapse>
        </>
        : ''}
        </>
  )
}