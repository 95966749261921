import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import theme from '../../../theme';
import { Alert, Autocomplete, Box, Checkbox, FormControlLabel, FormGroup, Snackbar, Typography } from '@mui/material';
import api from '../../../services/api';


export const module = 'empresa/token'
export default function FormDialog(props) {
  const [handlerSnack, setHandlerSnack] = React.useState()

  const [token, setToken] = React.useState(false)
  const item = {
    description: '',

  }


  const [state, setState] = React.useState(item);

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  React.useEffect(() => {
    if (props.open) {

     
    }

  }, [props.id, handlerSnack])

  React.useEffect(() => {
    if (props.open) {
      setToken(false)
    
    }

  }, [props.open])


  const getEditInfo = () => {
    setState(item)
    if (!props.id) return
    api.get(`${module}/find/${props.id}`)
      .then(res => {
        setState(res.data.__data__)


      }).catch(err => setState(item))
  }
  const showNotify = (msg, sev = "success") => {
    setMsgSnack(msg)
    setSevSnack(sev)
    setHandlerSnack(true)
  }

  const [msgSnack, setMsgSnack] = React.useState('')
  const [sevSnack, setSevSnack] = React.useState('')
  const handleSubmit = (event) => {

    const handlerClose = () => {
      props.handlerClose()
    }
    event.preventDefault()
   
      api.post(`${module}/create/${props.empresa_id}`, state)
        .then((res) => {
          if (res.data.error){

            showNotify(res.data.error, 'error')
            return
          }
          showNotify("Token gerado com sucesso.")
          setToken(res.data.token)
          return
        }).catch(() => {
          showNotify("Um erro ocorreu...", 'error')
        })
    
  }

  return (
    <div>
      <Snack open={handlerSnack} message={msgSnack} severity={sevSnack} handlerSnack={() => setHandlerSnack(false)} />
      <Dialog open={props.open} fullWidth={true}
        maxWidth='md'>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <DialogTitle>
            {!token? 'Criar Token' : `Token gerado: ${token}`}
          </DialogTitle>
          <DialogContent>
           {!token ?
            <TextField
            required
              size="small"
              margin="dense"
              id="description"
              name="description"
              label="Descrição do token"
              type="text"
              fullWidth
              value={state.description}
              onChange={handleChange}
              variant="outlined"
              /> : ""
            }

          </DialogContent>
          <Box>


            <Typography  component={"p"} ml={4} mt={2} >
              {token ? "Esse token é gerado apenas uma vez. Não é  possível visualizá-lo novamente.": ""}
            </Typography>
          </Box>

          <DialogActions>
            <Button sx={{ color: theme.palette.text.primary }}  onClick={props.handlerClose}>{props.id ? 'Cancelar' : "Fechar"}</Button>
            <Button sx={{ color: theme.palette.text.primary }} disabled={token} type='submit'> {!props.id ? 'Gerar' : "Editar"}</Button>
          </DialogActions>
        </Box>
      </Dialog>
    </div>
  );
}

const Snack = (props) => {
  return (
    <Snackbar
      open={props.open}
      autoHideDuration={6000}
      onClose={() => { props.handlerSnack() }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      <Alert onClose={() => { props.handlerSnack() }} severity={props.severity ? props.severity : 'error'} sx={{ width: '100%' }}>
        {props.message}
      </Alert>
    </Snackbar>
  )
}