import { DataArray, DnsOutlined, Height } from '@mui/icons-material';
import { Badge, Box, Paper, Tooltip, Typography } from '@mui/material';
import { green, grey, orange, red, yellow } from '@mui/material/colors';
import React, { memo } from 'react';
import { Handle, Position, } from 'react-flow-renderer';
import styled from 'styled-components';
import ListMenuContext, { useMenuContext } from './MenuContext'
import theme from '../theme'
const Node = styled.div`
padding: 1px;
border-radius: 5px;
background: ${(props) => props.theme.nodeBg};
color: ${(props) => props.theme.nodeColor};
border: 0px solid ${(props) => (props.selected ? props.theme.primary : props.theme.nodeBorder)};

.react-flow__handle {
  background: ${(props) => props.theme.primary};
  width: 0px;
  height: 0px;
  border: 0px;
  
  border-radius: 20px;
}

.react-flow__handle{
  left: 15px;
    top: 0px;
  }
  
  
  `;


export default memo(({ data, selected}) => {
  const MenuContext = useMenuContext()



  const [isOffline, setIsOffline] = React.useState(false);
  const [problems, setProblems] = React.useState(data.problems);
  const [hasOtherAlarms, setHasOtherAlarms] = React.useState(false);

  
  React.useEffect(() => {
    setProblems(data.problems)
    setIsOffline(data.problems?.filter(p => p.name === "Indisponível por PING").length > 0);
    setHasOtherAlarms(data.problems?.filter(p => p.name !== "Indisponível por PING").length > 0);

  }, [data.problems] );
  
  return (

    <>
      <Box onClick={() => MenuContext.isOpen ? MenuContext.setisOpen(false) : ''} onContextMenu={(e) => {
        e.preventDefault();
        MenuContext.isOpen ? MenuContext.setisOpen(false) : MenuContext.setStatus({ open: true, x: e.clientX, y: e.clientY })
        if(!MenuContext.isOpen){

          MenuContext.setStatus({ open: true, x: e.clientX, y: e.clientY })
          MenuContext.setPayload({
            cod_empresa: data.cod_empresa,
            ip: data.ip,
            name: data.label
          })
        } 
      }}>
        <Paper elevation={1}
          sx={data.label == "" ? {} : {
            color: selected ? isOffline ? red[800] : '#2196f3' : isOffline ? red['A700'] : hasOtherAlarms ? orange[900] : theme.palette.mode == 'dark' ? '#ede7f6' : grey[900],
            zIndex: 1,
            border: '2px solid ',
            borderRadius: 10
          }}
        >

          <Node selected={selected}>
            <Handle type="target" position={Position.Left} />

            {data.label == '' ? '' : <>
              <Box display={'flex'} justifyContent={'center'}>
                <Badge badgeContent={isOffline ? 0 : data?.problems.length} color="warning" sx={{
                  "& .MuiBadge-badge": {
                    color: 'white',
                    backgroundColor: red[900],
                  }
                }}>
                  <Tooltip title={problems.map(p=>
                  <Typography  key={data.ip | data.label} variant="body1" component={"div"} >{p.name}</Typography>)
                  }>
                    < DnsOutlined />
                  </Tooltip>
                </Badge>
              </Box>

            </>}
            <Handle type="source" position={Position.Left} />
          </Node>
        </Paper>

        {data.label == '' ? '' :
          <Box position={'absolute'}

            sx={{
              color: 'white',
              ml: -4, mt: .6,
              width: 100

            }}
          >
            <Paper elevation={1}
              sx={{
                backgroundColor: theme.palette.mode == 'dark' ? grey[900] : grey[100],
                padding: 0.6,
              }}
            >
              <Typography fontSize={8} align='center' >
                {data.label}  {data?.ip} - {data?.model}
              </Typography>

            </Paper>
          </Box>
        }
      </Box>
      <Box sx={{
        position: 'absolute'
      }}>

      </Box>
    </>
  );
});