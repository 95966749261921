import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import theme from '../theme';
import { Alert, Box, Checkbox, FormControlLabel, FormGroup, Snackbar } from '@mui/material';
import api from '../services/api';
import { FormControl } from '@mui/material';
import { Card } from '@mui/material';
import { CardContent } from '@mui/material';
import { Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import moment from "moment";
import 'moment/locale/pt-br'


export default function FormDialog(props) {
  const [handlerSnack, setHandlerSnack] = React.useState()
  const [ acks, setAcks] = React.useState([])
  const item = {
    description: '',
    cod_empresa: props.codEmpresa,
    event_ids: props.eventId,
    detail: props.detailAlarms

  }
  const [state, setState] = React.useState(item);

  const idIgnore =  [0, 123456789] // ids que não podem ser vistados diretos no zabbix e sim direcionados para o banco de dados
  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  React.useEffect(()=>{
    if(!props.open) return
    setState(item)
    getAcks()
    console.log(props.eventId)
  },[ handlerSnack, props.codEmpresa,props.open  ,props.eventId])


  const getAcks = ()=>{
    if(!props.codEmpresa || typeof props.eventId === 'object' ) {
      setAcks([])
      return
    }
     api.get(
      idIgnore.includes(props.eventId) ? `dashboard/ack_other/${props.eventId}/${props.codEmpresa}/${props.hostid}` :
      `dashboard/acks/${props.codEmpresa}/${props.eventId}/`)
     .then(res=>{
      setAcks(res.data)

     }).catch(err=> setState(item))
  }
  const showNotify = (msg,sev="success")=>{
    setMsgSnack(msg)
    setSevSnack(sev)
    setHandlerSnack(true)
  }
  
  const [ msgSnack, setMsgSnack ] = React.useState('')
  const [ sevSnack, setSevSnack ] = React.useState('')
  const handlerClose = ()=>{
    props.handlerClose()
  }
  const handleSubmit = (event)=>{
    event.preventDefault()
    if( idIgnore.includes(props.eventId)) {
    
      api.post('dashboard/ack_other/', {
        hosts: [props.hostid],
        cod_empresa: props.codEmpresa,
        description: state.description,
        severity: props.severity,
      }).then(()=>{
        showNotify("Vistado com sucesso.", "success")
        handlerClose()
      }).catch((err)=>{
        console.log(err)
        showNotify("Um erro ocorreu...",'error')
      })
    }else{

      api.post('dashboard/acks/', state)
      .then(()=>{
        showNotify("Vistado com sucesso.", "success")
        handlerClose()
      }).catch((err)=>{
        console.log(err)
        showNotify("Um erro ocorreu...",'error')
      })
    }
      
    }

  return (
    <div>
      <Snack open={handlerSnack} message={msgSnack} severity={sevSnack} handlerSnack={()=>setHandlerSnack(false)}/>
      <Dialog open={props.open} fullWidth={true}
        maxWidth='sm'>
        <Box component="form" onSubmit={handleSubmit}  sx={{ mt: 1 }}>
        <DialogTitle>
            Reconhecimento de alarmes:
        </DialogTitle>
        <DialogContent>
        <FormControl fullWidth>

        <TextField
            multiline
            rows={10}
            required
            autoFocus
            margin="dense"
            id="description"
            name="description"
            label="Detalhes:"
            type="text"
            fullWidth
            value={state.ack}
            onChange={handleChange}
            variant="outlined"
            size="small"
          />        
          </FormControl>
         { props.tags!="" && props.tags!=" "&& <Alert  sx={{mt: 1, backgroundColor: grey[1000]}} severity="warning">
          <Typography>{props.tags}</Typography>
          </Alert>}
          {acks.map((ack,i)=>
            <Alert key={i} sx={{mt: 1, backgroundColor: grey[1000]}} severity="info">
              <Typography>{moment(ack.clock).locale('pt-br').format('DD-MM-YYYY HH:mm:ss')} - Vistado {moment(ack.clock).locale('pt-br').fromNow()}:</Typography>
              {ack.description} </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button sx={{color: theme.palette.text.primary}} onClick={handlerClose}>Cancelar</Button>
          <Button sx={{color: theme.palette.text.primary}} type='submit'>Reconhecer</Button>
        </DialogActions>
          </Box>
      </Dialog>
    </div>
  );
}

const Snack = (props)=>{
  return  (
    <Snackbar
    open={props.open}
    autoHideDuration={6000}
    onClose={() => {props.handlerSnack()}}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }} 
    >
     <Alert  onClose={() => {props.handlerSnack()}} severity={props.severity ? props.severity : 'error'} sx={{ width: '100%' }}>
    {props.message}
  </Alert>
  </Snackbar>
  )
}