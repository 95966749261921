import { Autocomplete, FormControl, TextField } from '@mui/material';
import React,{useState} from 'react'
import api from '../services/api'


export default function EmpresaSelect({handler}) {
    const [data, setData] = useState([])
    const [value, setValue] = React.useState(null)
    React.useLayoutEffect(() => {
    
        api.get('user/all')
            .then(res => {
                setData(res.data)
            }).catch((err) => {
                if (err.response.status == 401) {
                    navigate('/401')
                }
                setData()
            })
    
      }, [])
  return (
    <FormControl 
    sx={{ width: 200, m:1}}
    >
      <Autocomplete
        size="small"
        id="empresas-outlined"
        options={data}
        onChange={(event, newValue) => {
          setValue(newValue);
          if(newValue==null){ 
            handler(null)
            return
          }
          handler(newValue.id)
        }}
        key={'mapList'}
        isOptionEqualToValue={(option, value) => option.id == value.id}
        getOptionLabel={(option) => option.username}
        value={value}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            label="Usuário"
          />
        )}
      />
    </FormControl>
  )
}
