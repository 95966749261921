import api from '../services/api'


export const extractItemsInBraces = str => (str.match(/{([^}]*)}/g) || []).map(item => item.substring(1, item.length - 1));


export function renderItem(label, items) {
  if (items.length === 0) return '';

  const reg = /{([^}]+)}/g;
  const itemsRaw = label.match(reg);
  if (itemsRaw === null) return label;

  const itemsNew = itemsRaw.map((item) => {
    const newitem = item.replace(/{|}/g, '');
    const foundItem = items.find((data) => data.key === newitem);
    if (foundItem) {
      const lastValue = foundItem.lastValue / 1000000000; // Converter para gigabits
      return lastValue.toFixed(2)+ "Gbps"; // Arredondar para duas casas decimais
    }
    return '';
  });


  
  let newLabel = label;
  itemsNew.forEach((value, index) => {
    newLabel = newLabel.replace(itemsRaw[index], value);
  });

  return newLabel
}

export function getItemZabbix(label, items) {
  if (items.length === 0) return false;  

  const reg = /{([^}]+)}/g;
  const itemsRaw = label.match(reg);
  if (itemsRaw === null) return false;

  const itemsNew = itemsRaw.map((item) => {
    const newitem = item.replace(/{|}/g, '');
    const foundItem = items.find((data) => data.key === newitem);
    if (foundItem) {
      return foundItem.itemid
      
    }
    return '';
  });


  
  return itemsNew
}
export function getItemTitle(label, items) {
  if (items.length === 0) return false;  

  const reg = /{([^}]+)}/g;
  const itemsRaw = label.match(reg);
  if (itemsRaw === null) return label;

  const itemsNew = itemsRaw.map((item) => {
    const newitem = item.replace(/{|}/g, '');
    const foundItem = items.find((data) => data.key === newitem);
    if (foundItem) {
      return foundItem.title
      
    }
    return '';
  });


  
  return itemsNew
}
function getItemsFromLabel(item){
    const reg = new RegExp('\{.+}','g')
    return item.match(reg)
}

export function renderLabel(label, cod_empresa) {
    const items = getItemsFromLabel(label);
    if (items == null) return label;
    console.log(items);
  
    const items_rend = 
      items.map(async (item) => {
        const reg = new RegExp('{|}', 'g');
        const item_no_bracket = item.replace(reg, '');
        const label_clean = item_no_bracket.split(':');
        
        const res = await getItem(
          label_clean[0],
          label_clean[1].replace('.last(0)', ''),
          cod_empresa
        );
        return res;
      })
    
    
    let new_label = label;
    items.forEach((item, i) => {
      new_label = new_label.replace(item, items_rend[i]);
    });
  
    return new_label;
  }

const exp = `VLAN 2675
TX:{10066-BGP:tx[Vlanif2675].last(0)}
RX:{10066-BGP:rx[Vlanif2675].last(0)}`
