import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import DialogTitle from '@mui/material/DialogTitle';
import theme from '../../../theme'
import { Alert, Autocomplete, Box, Checkbox, FormControl, FormControlLabel, FormGroup, MenuItem, Select, Snackbar } from '@mui/material';
import api from '../../../services/api';




export default function FormDialog(props) {
  const [handlerSnack, setHandlerSnack] = React.useState()
  const [operadoras, setOperadoras] = React.useState([])
  const item = {
    empresa_id: props.empresa_id,
    tipo_circuito: '',
    circuito: '',
    banda_contratada: '',
    operadora_id: '',

    observacao: '',

  }

  const getOperadora = () => {

    api.get(`/empresa/operadora/all`).then(res => {
      setOperadoras(res.data)
    })
      .catch(err => console.log(err))
  }
  const [state, setState] = React.useState(item);

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };


  React.useEffect(() => {
    if (!props.open) return
    getEditInfo()
    getOperadora()

  }, [props.open, props.id, handlerSnack])


  const getEditInfo = () => {
    setState(item)
    if (!props.id) return
    api.get(`empresa/link/find/${props.id}`)
      .then(res => {
        let data = res.data.__data__

        setState(data)


      }).catch(err => setState(item))
  }
  const showNotify = (msg, sev = "success") => {
    setMsgSnack(msg)
    setSevSnack(sev)
    setHandlerSnack(true)
  }

  const [msgSnack, setMsgSnack] = React.useState('')
  const [sevSnack, setSevSnack] = React.useState('')




  const handleSubmit = (event) => {
  
    const handlerClose = () => {
      props.handlerClose()
    }
    event.preventDefault()
    if (!props.id) {
      api.post('empresa/link/create', state)
        .then(() => {
          showNotify("Registro adicionado com sucesso.")
          handlerClose()
        }).catch((err) => {
          console.log(err)
          showNotify("Um erro ocorreu...", 'error')
        })
    } else {
      api.put(`empresa/link/edit/${props.id}/`, state)
        .then(() => {
          showNotify("Registro editado com sucesso.")
          handlerClose()
        }).catch(() => {
          handlerClose()
          showNotify("Um erro ocorreu...", 'error')
        })
    }
  }

  return (
    <div>
      <Dialog open={props.open} fullWidth={true}
        maxWidth='sm'>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <DialogTitle>
            {!props.id ? 'Criar Upstream/Link' : "Editar Upstream/Link"}
          </DialogTitle>
          <DialogContent>
            <FormControl fullWidth>
              <Select
                required
                size="small"
                labelId="Operadora"
                id="operadora_id"
                name='operadora_id'
                value={state.operadora_id}
                label="Operadora"
                onChange={handleChange}
              >

                {operadoras ? operadoras.map((operadora, i) =>
                  <MenuItem key={operadora.id} value={operadora.id}>{operadora.nome}</MenuItem>
                ) : ''}
              </Select>
            </FormControl>
            <TextField
              required
              size="small"
              margin="dense"
              id="tipo_circuito"
              name="tipo_circuito"
              label="Tipo do Circuito"
              type="text"
              fullWidth
              value={state.tipo_circuito}
              onChange={handleChange}
              variant="outlined"
            />

            <TextField
              required
              size="small"
              margin="dense"
              id="circuito"
              name="circuito"
              label="Circuito"
              type="text"
              fullWidth
              value={state.circuito}
              onChange={handleChange}
              variant="outlined"
            />
            <TextField
              required
              size="small"
              margin="dense"
              id="banda_contratada"
              name="banda_contratada"
              label="Banda Contratada"
              type="text"
              fullWidth
              value={state.banda_contratada}
              onChange={handleChange}
              variant="outlined"
            />
            <TextField
              size="small"
              rows={5}
              multiline
              margin="dense"
              id="observacao"
              name="observacao"
              label="Observação"
              type="text"
              fullWidth
              value={state.observacao}
              onChange={handleChange}
              variant="outlined"
            />

          </DialogContent>
          <DialogActions>
            <Button sx={{ color: theme.palette.text.primary }} onClick={props.handlerClose}>Cancelar</Button>
            <Button sx={{ color: theme.palette.text.primary }} type='submit'> {!props.id ? 'Adicionar' : "Editar"}</Button>
          </DialogActions>
        </Box>
      </Dialog>
    </div>
  );
}

const Snack = (props) => {
  return (
    <Snackbar
      open={props.open}
      autoHideDuration={6000}
      onClose={() => { props.handlerSnack() }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      <Alert onClose={() => { props.handlerSnack() }} severity={props.severity ? props.severity : 'error'} sx={{ width: '100%' }}>
        {props.message}
      </Alert>
    </Snackbar>
  )
}