import React, { useState, useEffect } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { useAuth } from '../auth/AuthProvider';
import CircleIcon from '@mui/icons-material/Circle';
import { green, red } from '@mui/material/colors';
import { memo } from 'react';
import api from '../services/api';
import { IconButton } from '@mui/material';
import { Logout } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';

function Monitor(props) {
  const auth = useAuth()
  
  
  
  const getSocketUrl = React.useCallback(() => {
    return new Promise((resolve) => {
      const url =process.env.REACT_APP_API_URL
      const domain = url.replace('https://', '').replace('http://', '');
      
      api.get('/login/token-ws').then((res) => {
        const socketUrl = `wss://${domain}/api/v1/ws/monitor?token=` + res.data.access_token;
        resolve(socketUrl);
      });
    });
  }, []);

  const { sendMessage, lastMessage, readyState, closeWebSocket } = useWebSocket(getSocketUrl,{
    //onOpen: () => console.log('opened'),
    //Will attempt to reconnect on all close events, such as server shutting down
    shouldReconnect: (closeEvent) => true,
    reconnectAttempts: 20,
    reconnectInterval: 10000,
  });

  useEffect(() => {
  
    if (lastMessage !== null) {
      // Processar a mensagem recebida
      //console.log('Received message:', lastMessage.data);
    }
  }, [lastMessage]);

  useEffect(() => {
    if (readyState === ReadyState.OPEN) {
    
      // Enviar um "Ping" a cada 5 segundos
      const pingInterval = setInterval(() => {
        sendMessage('Ping');
      }, 5000);

      // Retornar uma função de limpeza para parar o envio do "Ping" quando a conexão for fechada
      return () => {
        clearInterval(pingInterval);
      };
    } else if (readyState === ReadyState.CLOSED) {
      // Socket fechado, executar auth.signout()
     //auth.signout();
    }
  }, [readyState]);
 

  return (
    <>
      <CircleIcon
        fontSize='10'
        sx={{
          ml: 1,
          color: readyState === ReadyState.OPEN ? green[500] : red[900],
        }}></CircleIcon>
      </>
  
  );
}

export default memo(Monitor);
