import { ThemeProvider } from '@emotion/react'
import { Box, Button, Container, IconButton } from '@mui/material'
import MUIDataTable from 'mui-datatables'
import * as React from 'react'
import theme from '../../../theme'
import api from '../../../services/api'
import AddIcon from '@mui/icons-material/Add';
import FormDialog from './FormDialog'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { module } from './FormDialog'
import { useAuth } from '../../../auth/AuthProvider'
import { useNavigate } from 'react-router-dom'
import { CalendarMonth } from '@mui/icons-material'
import FormDialogTimes from './FormDialogTimes'

export default function (props) {
    const navigate = useNavigate()
    const [handlerDialog, setHandlerDialog] = React.useState(false)
    const [handlerDialogTime, setHandlerDialogTime] = React.useState(false)
    const [update, setUpdate] = React.useState(false)
    const [id, setId] = React.useState()
    const auth = useAuth()
    React.useEffect(() => {
        setUpdate(false)
        updateData()

    }, [handlerDialog, update])
    const [data, setData] = React.useState()
    const columns = [
        {
            name: 'id',
            label: 'Id',

        },
        {
            name: 'nome',
            label: "Nome",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: 'telefone',
            label: "Telefone",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: 'cargo',
            label: "Cargo",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: 'email',
            label: "Email",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: 'observacao',
            label: "Observação",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "actions",
            label: "Ações",
            options: {
                customBodyRenderLite: (dataIndex, rowIndex) =>
                    <>
                        <Button disabled={!auth.hasPerm('empresa.get')} sx={{ color: theme.palette.text.primary }} onClick={() => {
                            const id = data[dataIndex].id
                            setId(id)
                            setHandlerDialogTime(true)

                        }}>
                            <CalendarMonth />
                        </Button>
                        <Button sx={{ color: theme.palette.text.primary }} disabled={!auth.hasPerm('empresa.edit')} onClick={() => {
                            const id = data[dataIndex].id
                            setId(id)
                            setHandlerDialog(true)
                        }}>
                            <EditIcon />
                        </Button>
                        <Button disabled={!auth.hasPerm('empresa.delete')} sx={{ color: theme.palette.text.primary }} onClick={() => {
                            const id = data[dataIndex].id
                            const name = data[dataIndex].nome
                            deleteItem(id, name)

                        }}>
                            <DeleteIcon />
                        </Button>
                    </>

            }
        }
    ]
    const deleteItem = (id, name) => {
        const conf = confirm(`Deseja realmente apagar o cargo: ${name}`)
        if (!conf) return
        api.delete(`${module}/delete/${id}/`).then(() => {
            setUpdate(true)
        }).catch(err => console.log(err))
    }
    const updateData = () => {
        api.get(`${module}/find/empresa/${props.empresa_id}`)
            .then(res => setData(res.data)).catch((err) => {
                if (err.response.status == 401) {
                    navigate('/401')
                }
                setData()
            })
    }
    const showFormDialog = () => {
        setId()
        setHandlerDialog(true)
    }
    const options = {
        filterType: 'checkbox',
        rowsPerPageOptions: [10, 30, 50],
        rowsPerPage: 10,
        rowHover: false,
        print: false,
        selectableRows: 'none',
        download: false,
        customToolbar: () => <IconButton onClick={showFormDialog} disabled={!auth.hasPerm('empresa.create')} sx={{ color: theme.palette.text.primary }}><AddIcon /></IconButton>,
        customToolbarSelect: () => { },
    }
    return (
        <Container maxWidth='lg' sx={{ mt: 0, mb: 0 }}>
            <ThemeProvider theme={theme}>
                <FormDialog open={handlerDialog} id={id} empresa_id={props.empresa_id} showNotify={props.showNotify} handlerClose={() => setHandlerDialog(false)} updateData={updateData} />
                <FormDialogTimes open={handlerDialogTime} id={id} empresa_id={props.empresa_id} data={data} showNotify={props.showNotify} handlerClose={() => setHandlerDialogTime(false)} updateData={updateData} />
                <Box sm={12} lg={10}>
                    <MUIDataTable
                        title={"Contatos"}
                        data={data}
                        columns={columns}
                        options={options}
                    />
                </Box>
            </ThemeProvider>
        </Container>
    )
}