import { Badge, Box, Tooltip, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { blueGrey, green, grey, orange, red } from "@mui/material/colors";
import './style.css'

import React, {memo} from "react";
import api from "../../services/api";

const Painel = memo(function(){
    const [data, setData] = React.useState([])

    const fetchData = () => {
        api.get('/dashboard/painel-premium').then(response => {
            const empresas = [...new Set(response.data.map(item => item.cod_empresa))]
            setData(empresas.map(empresa => {
              const warning_alarm_ack = response.data.filter(item => item.cod_empresa === empresa && item.severity == 4 && item.acks ==1)
              const critical_alarm_ack = response.data.filter(item => item.cod_empresa === empresa && item.severity == 5 && item.acks ==1)
              const warning_alarm = response.data.filter(item => item.cod_empresa === empresa && item.severity == 4 & item.acks ==0)
              const critical_alarm = response.data.filter(item => item.cod_empresa === empresa && item.severity == 5 & item.acks ==0)
              
              return {cod_empresa: empresa,  
                  warning_alarm:  warning_alarm[0]?.total ,
                  critical_alarm: critical_alarm[0]?.total,
                  warning_alarm_ack: warning_alarm_ack[0]?.total,
                  critical_alarm_ack: critical_alarm_ack[0]?.total

              }
            }).sort((a, b) => a.cod_empresa - b.cod_empresa))
            }).catch(err=> console.log(err))
    }
    React.useEffect(() => {
        fetchData()
    },[])


    React.useEffect(() => {
        const interval = setInterval(() => {
           fetchData()
        }, 60000)
        return () => clearInterval(interval)
    }, [])
    return (
        <Box
        
        overflow={"hidden"}
        sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            ml:2,
            mr:2,
            flexGrow:2
           
            

        }}
        >
          <Box
           className="letreiro"
           display={"flex"}
           flex={"nowrap"}
           flexDirection={"row"}
           overflow={"hidden"}
          >
            {data.map((item, index) => {
                return (
                    <Item
                        key={index}
                        cod_empresa={item.cod_empresa}
                        warning_alarm_ack={item.warning_alarm_ack}
                        critical_alarm_ack={item.critical_alarm_ack}
                        warning_alarm={item.warning_alarm}
                        critical_alarm={item.critical_alarm}
                    />
                )
            })
            }
          </Box>
       

     
        </Box>
    )
})


function Item({cod_empresa, warning_alarm, critical_alarm, warning_alarm_ack, critical_alarm_ack}){
    return (

        <Ticker
         
        >
            <Tooltip title="Empresa Premium" arrow>
            <Typography 
                fontWeight={"bold"}
                sx={{
                    
                    p: 1,
                    fontSize: 23,
                    backgroundColor: blueGrey[500],
                    borderRadius: 0,
                    padding: .2,
                    minWidth: 37,
                    textAlign: 'center'
                }}
                ml={.3}
                component="div"
            >{cod_empresa}</Typography>
            </Tooltip>
            <Tooltip title="Alarmes não vistados de criticidade 'Desastre'" arrow>
            <Typography
              sx={{
                padding: .5,
                fontSize: 23,
                backgroundColor: red[500],
                borderRadius: 0,
                padding: .2,
                minWidth: 37,
                textAlign: 'center'
            }}
                ml={.4}
                fontWeight={"bold"}
                color={"white"}
                component="div"
            >{critical_alarm || 0}</Typography>
            </Tooltip>
            <Tooltip title="Alarmes não vistados de criticidade 'Alta'" arrow>
            <Typography
                  sx={{
                    
                    padding: .5,
                    fontSize: 23,
                    backgroundColor: orange[500],
                    borderRadius: 0,
                    padding: .2,
                    minWidth: 37,
                    textAlign: 'center'
                }}
                ml={.4}
                fontWeight={"bold"}
                color={"white"}
                component="div"
            >{warning_alarm || 0}</Typography>
            </Tooltip>
            <Tooltip title="Alarmes vistados de criticidade 'Desastre'" arrow>
            <Typography
              sx={{
                padding: .5,
                fontSize: 23,
                backgroundColor: green[500],
                borderRadius: 0,
                padding: .2,
                minWidth: 37,
                textAlign: 'center'
            }}
                ml={.4}
                fontWeight={"bold"}
               
                component="div"
            >{critical_alarm_ack || 0}</Typography>
            </Tooltip>
            <Tooltip title="Alarmes vistados de criticidade 'Alta'" arrow>
            <Typography
            color={"white"}
              sx={{
                padding: .5, 
                fontSize: 23,
                backgroundColor: green[500],
                borderRadius: 0,
            
                padding: .2,
                minWidth: 37,
                textAlign: 'center'
            }}
                ml={.4}
                fontWeight={"bold"}
                
                component="div"
            >{warning_alarm_ack || 0}</Typography>
            </Tooltip>
           
           
        </Ticker>
        )

}

function Ticker({children}){

    return (
        <Box
            display={"flex"}
            flexWrap={"nowrap"}
            sx={{
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: "#272727",
                p: 1,
                m: 2,
                color: grey[50]
            }}
        >
            {children}
   
            
        </Box>
    )
}

export default Painel