import * as React from "react";
import {
  Routes,
  Route,
  Link,
  Swit,
  useNavigate,
  useLocation,
  Navigate,
  Outlet,
} from "react-router-dom";
import  Dashboard from './dashboard/Dashboard'
import LoginPage from './Login/LoginPage'
import { AuthProvider, RequireAuth} from './auth/AuthProvider'
import ApiZabbix from './ApiZabbix'
import  Alarms from './alarms/Alarms'
import User from "./User";
import Role from "./User/Role";
import { Container, ThemeProvider, Typography } from "@mui/material";
import theme from "./theme";
import NotAuth from "./NotAuth";
import Empresa from "./Empresa";
import Servico from './Empresa/Servico'
import Operadora from './Empresa/Operadora'
import Map from './Map'
import { MenuContextProvider } from "./Map/MenuContext";
import Monitor from "./Monitor";
import UserOnline from "./User/UserOnline";
import ProblemsReport from './Report/Problems'
import HistoryReport from './Report/History'
import AcksReport from './Report/Acks'
import AcksCountReport from './Report/AcksCount'
import UsersReport from './Report/Users'
import Recover from "./recovery/";
import ChangePassword from "./recovery/changePassword";
import PPIGroup from "./PPI/Groups";
import PPI from "./PPI";
import Servico_CH from './Empresa/Servico_CH'
import Tag_CH from './Empresa/Tags'
import Audit from "./Report/Audit";
import Token from "./Token"
import ReportEmpresa from "./Report/History/ProblemsEmpresa";
import ReportHost from "./Report/History/ProblemsHost";
import ReportTecnica from "./Report/History/ProblemsTecnica";
import ApiDown from "./Report/History/ApiDown";

export default function App() {
  return (
    <AuthProvider>
      <MenuContextProvider>

    <Routes>
        <Route>
          <Route path="/" element={
            <RequireAuth >
                <Dashboard />
              </RequireAuth>
            }>
                <Route path="/" element={
                  <Alarms/>
                }/>     
          <Route path="/map"
            element={<Map/>}/>
          <Route path="api"
            element={<ApiZabbix/>}/>
          <Route path="user"
            element={<User/>}/>
          <Route path="user/online"
            element={<UserOnline/>}/>
          <Route path="user/role"
            element={<Role/>}/>
          <Route path="empresa"
            element={<Empresa/>}/>
          <Route path="empresa/servico"
            element={<Servico/>}/>
          <Route path="empresa/servico-ch"
            element={<Servico_CH/>}/>
          <Route path="empresa/tag-ch"
            element={<Tag_CH/>}/>
          <Route path="empresa/operadora"
            element={<Operadora/>}/>
          <Route path="report/problems"
            element={<ProblemsReport/>}/>
          <Route path="report/history"
            element={<HistoryReport/>}/>
          <Route path="report/acks"
            element={<AcksReport/>}/>
          <Route path="report/users"
            element={<UsersReport/>}/>
          <Route path="report/ackscount"
            element={<AcksCountReport/>}/>
          <Route path="report/audit"
            element={<Audit/>}/>
          <Route path="report/empresa"
            element={<ReportEmpresa/>}/>
          <Route path="report/host"
            element={<ReportHost/>}/>
          <Route path="report/tecnica"
            element={<ReportTecnica/>}/>
          <Route path="token"
            element={<Token/>}/>
          <Route path="ppi/group"
            element={<PPIGroup/>}/>
          <Route path="ppi"
            element={<PPI/>}/>
          <Route path="report/api-down"
            element={<ApiDown/>}/>
            
          <Route path="/401" element={<NotAuth />} />

          
            <Route path='*' element={<NotFound />}/>
            </Route>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/recovery" element={<Recover />} />
          <Route path="/change-password" element={<ChangePassword />} />
        </Route>
      </Routes>
      </MenuContextProvider>
    </AuthProvider>
  );
}

function NotFound() {
  return (
    <Container  maxWidth='lg' sx={{ mt: 4, mb: 4 }}>
    <ThemeProvider theme={theme}>
    <Typography component="h2" variant="h6" color="primary" gutterBottom>
      Página não encontrada.
    </Typography>
    </ThemeProvider>
      </Container>
  )
}