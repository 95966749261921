import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import theme from '../theme';
import { Alert, Box, Checkbox, CircularProgress, FormControlLabel, FormGroup, Snackbar } from '@mui/material';
import api from '../services/api';
import { FormControl } from '@mui/material';
import { Card } from '@mui/material';
import { CardContent } from '@mui/material';
import { Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import moment from "moment";
import 'moment/locale/pt-br'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Paper } from '@mui/material';



export default function FormDialog({ id, handlerClose, open }) {

  const [contatos, setContatos] = React.useState([])
  const [action, setAction] = React.useState('')
  React.useEffect(() => {
    setAction('')

    if (open) getPPI()

  }, [open, id])


  const getPPI = () => {

    api.get(`ppi/ppi/find-by-ppi/${id}`)
      .then(res => {
        setAction(res.data?.__data__?.action)
      }).catch(err => {
        setContatos([])

      })

  }


  return (

    <Dialog open={open} fullWidth={true} scroll={'paper'}
      maxWidth='lg'>

      <DialogTitle>
        Procedimento do alarme:
      </DialogTitle>
      <DialogContent>
        {
          action.split('\n').map((item, index) => (
            <Typography key={index} variant="body2" component="div" sx={{ flexGrow: 1, marginBottom: 1 }}>
              {item}
            </Typography>
          ))
        }


      </DialogContent>
      <DialogActions>
        <Button sx={{ color: theme.palette.text.primary }} onClick={handlerClose}>Fechar</Button>

      </DialogActions>
    </Dialog>

  );
}
