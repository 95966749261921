import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import api from '../services/api'
import { redirect, useNavigate, Navigate } from 'react-router-dom';
import { useAuth } from '../auth/AuthProvider';
import theme from '../theme';
import logoBlack from './logo_black.png'
import logoWhite from './logo_white.png'
import { grey, red } from '@mui/material/colors';
import { Paper } from '@mui/material';
import { useLocation } from 'react-router-dom';

export default function SignIn() {
  const location = useLocation()

  React.useEffect(()=>{
    
    if (auth.user!=null)  {
     navigate('/')
    }
  })
  const navigate = useNavigate()
  const auth = useAuth()
 
  

  const [errorInput, setErrorInput ] = React.useState({
    username: false,
    password : false,
  })
  const [errorMsg, setErrormsg ] = React.useState({
    severity: "error",
    msg: "",
    show: false
  })
  const loginApi = (cred)=>{
    let from = location.state?.from?.pathname || "/";
    const redirectTo = ()=>{
      
      navigate(from,{replace:true})
    }
    const cbErr = ()=> setErrormsg({
      msg :"Credenciais inválidas.", show:true, severity: 'error' 
    }) 
    auth.signin(cred, redirectTo, cbErr)
    
  }

  const handleSubmit = (event) => {
    setErrorInput(obj=>({...obj, username: false, password: false}))
    setErrormsg(errorMsg=>({  ...errorMsg,show:false})) 
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const cred = ({
      username: data.get('username'),
      password: data.get('password'),
    });
    if(cred.password.length<5){
      setErrorInput(obj=>({...obj, password: true}))
    }
    if(cred.username.length<1){
      setErrorInput(obj=>({...obj, username: true}))
    }
    if(!cred.username || !cred.password || cred.password.length<5 || cred.username.length<1){
      setErrormsg(m=>({show:true, msg: "Por favor preencha o formulário corretamente.", severity: 'warning' }))
      return;
    }
    loginApi(cred)
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs"
      >
        <CssBaseline />
        <Paper  elevation={2}
          sx={{
            p: 5,
            backgroundColor: theme.palette.mode == "light" ? grey[100]: grey[900],
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          
            <img  style= {{ width: '100%'}}src={theme.palette.mode == "light"? logoBlack : logoWhite}></img>
     
        
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              error={errorInput.username}
              margin="normal"
              required
              fullWidth
              id="username"
              label="Usuário"
              name="username"
              autoComplete="username"
              autoFocus
              helperText= {errorInput.username ? "Verifique este campo.":''}
            />
            <TextField
              error={errorInput.password}
              margin="normal"
              required
              fullWidth
              name="password"
              label="Senha"
              type="password"
              id="password"
              autoComplete="current-password"
              helperText={errorInput.password ? "Verifique este campo.":''}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Acessar
            </Button>
            <Link to={"/recovery"} style={{ textDecoration: 'none', color: red[900] }}>
                <Typography variant="body2" color="text.secondary" align="center">
                  Esqueci minha senha
                </Typography>
              </Link>
            <Alert severity={errorMsg.severity}
            sx={{ display: errorMsg.show ? '':'none'  }}
            >{errorMsg.msg}</Alert>
          </Box>
        </Paper>
      </Container>
    </ThemeProvider>
  );
}
