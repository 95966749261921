import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import theme from '../theme';
import { Alert, Box, Checkbox, CircularProgress, FormControlLabel, FormGroup, Snackbar } from '@mui/material';
import api from '../services/api';
import { FormControl } from '@mui/material';
import { Card } from '@mui/material';
import { CardContent } from '@mui/material';
import { Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import moment from "moment";
import 'moment/locale/pt-br'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Paper } from '@mui/material';



export default function FormDialog({ codEmpresa, handlerClose, open }) {

  const [contatos, setContatos] = React.useState([])
  const [observacaoDash, setObservacaoDash] = React.useState('')
  React.useEffect(() => {
    if (open) getActiveContato()

  }, [open, codEmpresa])


  const getActiveContato = () => {

    api.get(`empresa/contato/expediente/find/ativo/${codEmpresa}`)
      .then(res => {
        const { contacts, observacao_dash } = res.data
        setContatos(contacts)
        setObservacaoDash(observacao_dash)
      }).catch(err => {
        setContatos([])

      })

  }


  return (

    <Dialog open={open} fullWidth={true} scroll={'paper'}
      maxWidth='lg'>

      <DialogTitle>
        Contatos:
      </DialogTitle>
      <DialogContent>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Nome</TableCell>
                <TableCell align="left">Email</TableCell>
                <TableCell align="left">Telefones</TableCell>
                <TableCell align="left">Obs.:</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {contatos.length > 0 ? contatos.map((row, i) => (
                <TableRow
                  key={row.conta_id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.nome}
                  </TableCell>
                  <TableCell align="left">{row.email}</TableCell>
                  <TableCell align="left">{row.telefone}</TableCell>
                  <TableCell align="left">{row.observacao}</TableCell>

                </TableRow>
              )) : null}
            </TableBody>
          </Table>
        </TableContainer>
        {
          observacaoDash ?
            <Alert severity="info" sx={{ marginTop: '20px' }}>
              {observacaoDash}
            </Alert>
            : ""
        }

      </DialogContent>
      <DialogActions>
        <Button sx={{ color: theme.palette.text.primary }} onClick={handlerClose}>Fechar</Button>

      </DialogActions>
    </Dialog>

  );
}
