import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import theme from '../../../theme';
import { Alert, Autocomplete, Box, Checkbox, FormControlLabel, FormGroup, Snackbar } from '@mui/material';
import api from '../../../services/api';


export const module = 'empresa/contato'
export default function FormDialog(props) {

  const item = {
    empresa_id: props.empresa_id,
    nome:'',
    telefone:'',
    email:'',
    cargo:'',
    observacao:'',
    

  }


  const [state, setState] = React.useState(item);
  
  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  React.useEffect(()=>{
    if(props.open) getEditInfo()
    
  },[props.open])



  const getEditInfo = ()=>{
    setState(item)
    if(!props.id) return
    api.get(`${module}/find/${props.id}`)
    .then(res=>{
      setState(res.data.__data__)
      setState(s=>({...s,empresa_id: props.empresa_id}))
    
    }).catch(err=> setState(item))
  }
  const { showNotify, submit } = props;
  
  
  const handleSubmit = (event)=>{
   console.log(state)
    const handlerClose = ()=>{
      props.handlerClose()
    }

    event.preventDefault()
    if(!props.id){
      api.post(`${module}/create`, state)
      .then(()=>{
          showNotify("Registro adicionado com sucesso.")
          handlerClose()
      }).catch(()=>{
          showNotify("Um erro ocorreu...",'error')
      })
    }else{
      api.put(`${module}/edit/${props.id}/`, state)
      .then(()=>{

          showNotify("Registro editado com sucesso.")
          handlerClose()
    }).catch(()=>{
        handlerClose()
        showNotify("Um erro ocorreu...",'error')
    })
    }
  }

  return (
    <div>
      <Dialog open={props.open} fullWidth={true}
        maxWidth='sm'>
        <Box component="form" onSubmit={handleSubmit}  sx={{ mt: 1 }}>
        <DialogTitle>
            {!props.id ? 'Novo contato' : "Editar contato"}
        </DialogTitle>
        <DialogContent>
               
          <TextField
          required
          size="small"
            margin="dense"
            id="nome"
            name="nome"
            label="Nome"
            type="text"
            fullWidth
           value={state.nome}
           onChange={handleChange}
            variant="outlined"
            />
          <TextField
          required
          size="small"
            margin="dense"
            id="telefone"
            name="telefone"
            label="Telefone"
            type="text"
            fullWidth
           value={state.telefone}
           onChange={handleChange}
            variant="outlined"
            />
          <TextField
          required
          size="small"
            margin="dense"
            id="email"
            name="email"
            label="Email"
            type="text"
            fullWidth
           value={state.email}
           onChange={handleChange}
            variant="outlined"
            />
          <TextField
          
          size="small"
            margin="dense"
            id="cargo"
            name="cargo"
            label="Cargo"
            type="text"
            fullWidth
           value={state.cargo}
           onChange={handleChange}
            variant="outlined"
            />
              <TextField
                
                multiline
                rows={4}
                size="small"
                margin="dense"
                id="observacao"
                name="observacao"
                label="Observações"
                type="text"
                fullWidth
                value={state.observacao}
                onChange={handleChange}
                variant="outlined"
            />
        
        </DialogContent>
        <DialogActions>
          <Button sx={{color: theme.palette.text.primary}} onClick={props.handlerClose}>Cancelar</Button>
          <Button sx={{color: theme.palette.text.primary}} type='submit'> {!props.id ? 'Adicionar' : "Editar"}</Button>
        </DialogActions>
          </Box>
      </Dialog>
    </div>
  );
}

const Snack = (props)=>{
  return  (
    <Snackbar
    open={props.open}
    autoHideDuration={6000}
    onClose={() => {props.handlerSnack()}}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }} 
    >
     <Alert  onClose={() => {props.handlerSnack()}} severity={props.severity ? props.severity : 'error'} sx={{ width: '100%' }}>
    {props.message}
  </Alert>
  </Snackbar>
  )
}