import * as React from 'react';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import zIndex from '@mui/material/styles/zIndex';
import { Box, Paper } from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import api from '../services/api'
import theme from '../theme';
export default function ListContextMenu() {

  const MenuContext = useMenuContext()

  const handlePort = (ports, protocol) => {
    const portsS = ports.split(',');
    console.log(portsS);
    
    switch (protocol) {
      case 'ssh':
        return portsS.length > 0 ? parseInt(portsS[0], 10) : 22;
  
      case 'winbox':
        return portsS.length > 1 ? parseInt(portsS[2], 10) : 8291;
  
      case 'telnet':
        return portsS.length > 2 ? parseInt(portsS[1], 10) : 23;
  
      default:
        return ports;
    }
  }
  
  return (

    <Paper elevation={2}
      sx={{
        borderRadius: 4
      }}
    >

      <List
        sx={{
          position: 'fixed',
          display: MenuContext.isOpen ? 'display' : 'none',
          width: '100%', maxWidth: 200, bgcolor: theme.palette.mode == "dark" ? blueGrey[800] :blueGrey[100],
          transform: `translate( ${MenuContext.status.x}px, ${MenuContext.status.y}px)`,
          transformOrigin: 'left top',
          zIndex: 10,

        }}
        component="nav"
        aria-labelledby="nested-list-subheader"

      >
        <ListItemButton onClick={() => {
          api.post('/map/access', MenuContext.payload).then(res => {

            const { username, password, porta } = res.data[0].__data__
            MenuContext.setisOpen(false)
            window.open(`ipv7winbox://${MenuContext.payload.ip}/${username}/${password}/${handlePort(porta, 'winbox')}`)
          }).catch(()=>{
            MenuContext.setisOpen(false)
            window.open(`ipv7winbox://${MenuContext.payload.ip}/semlogin/semlogin/8291`)
          })
        }}>

          <ListItemText primary="Winbox" />
        </ListItemButton>
        <ListItemButton
          onClick={() => {
            api.post('/map/access', MenuContext.payload).then(res => {
             
              const { username, password, porta } = res.data[0].__data__
              MenuContext.setisOpen(false)
              window.open(`ipv7ssh://${MenuContext.payload.ip}/${username}/${password}/${handlePort(porta, 'ssh')}`)
            }).catch(()=>{
              MenuContext.setisOpen(false)
              window.open(`ipv7ssh://${MenuContext.payload.ip}/semlogin/semlogin/22`)
            })
          }}
        >
          <ListItemText primary="SSH" />
        </ListItemButton>
        <ListItemButton
          onClick={() => {
            api.post('/map/access', MenuContext.payload).then(res => {
             
              const { username, password, porta } = res.data[0].__data__
              MenuContext.setisOpen(false)
              window.open(`ipv7telnet://${MenuContext.payload.ip}/${username}/${password}/${handlePort(porta, 'telnet')}`)
            }).catch(()=>{
              MenuContext.setisOpen(false)
              window.open(`ipv7telnet://${MenuContext.payload.ip}/semlogin/sempapss/22`)
            })
          }}
        >
          <ListItemText primary="Telnet" />
        </ListItemButton>
        <ListItemButton
          onClick={() => {
            MenuContext.setisOpen(false)
            window.open(`ipv7ping://${MenuContext.payload.ip}`)
          }}>
          <ListItemText primary="Ping"
          />
        </ListItemButton>
        <ListItemButton
          onClick={() => {
            MenuContext.setisOpen(false)
            window.open(`ipv7tracert://${MenuContext.payload.ip}`)
          }}>
          <ListItemText primary="Tracert"
          />
        </ListItemButton>
        <ListItemButton
          onClick={() => {
            MenuContext.setisOpen(false)
            navigator.clipboard.writeText(MenuContext.payload.ip)
          }}>
          <ListItemText primary="Copiar IP"
          />
        </ListItemButton>
        <ListItemButton
          onClick={() => {
            MenuContext.setisOpen(false)
            navigator.clipboard.writeText(MenuContext.payload.name)
          }}>
          <ListItemText primary="Copiar nome do host"
          />
        </ListItemButton>

      </List>
    </Paper>
  );
}

const MenuContext = React.createContext(null);

export function MenuContextProvider({ children }) {

  const [payload, setPayload] = React.useState({
    cod_empresa: '',
    ip: '',
    name:''
  })
  const [status, setStatus] = React.useState({
    open: false,
    x: 0, y: 0
  })

  const setisOpen = (open) => setStatus(s => ({ ...s, open: open }))
  const isOpen = status.open
  let value = { status, setStatus, setisOpen, isOpen, setPayload, payload }
  return <MenuContext.Provider value={value}>{children}</MenuContext.Provider>
}

export function useMenuContext() {
  return React.useContext(MenuContext)
}

export function HandleMenuContext({ children }) {
  const MenuContext = useMenuContext()
  return (
    <div onClick={() => {
      MenuContext.setisOpen(false)
    }} onContextMenu={(e) => {

      e.preventDefault();
      MenuContext.isOpen ? MenuContext.setisOpen(false) : ''
    }}>

      {children}
    </div>
  )
}