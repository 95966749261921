import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import api from "../../services/api";
import { Chip } from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};



export default function MultipleSelectCheckmarks({handler}) {
  const [empresas, setEmpresas] = React.useState([]);
  const [empresasSelected, setEmpresasSelected] = React.useState([]);
  const getEmpresas = () => api.get('/empresa/all').then(res => setEmpresas(res.data.filter(empresa=> empresa.status ==1).map(empresa => empresa.cod_empresa)))
  const handleChange = (event) => {

    const {
      target: { value },
    } = event;
    setEmpresasSelected(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    handler( typeof value === 'string' ? value.split(',') : value,)
  };

  React.useLayoutEffect(() => {
    getEmpresas()
  }, [])

  return (
    <div>
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="demo-multiple-checkbox-label"
          sx={{ mt : -1}}
        >Empresa</InputLabel>
        <Select
          size='small'
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={empresasSelected}
          onChange={handleChange}
          input={<OutlinedInput label="Empresa" />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
        >
           <MenuItem key='all' value='all'>
              <Checkbox checked={empresasSelected.length == empresas.length}
                onChange={(e) => {
                  if (e.target.checked) {
                    setEmpresasSelected(empresas)
                    handler(empresas)
                  } else {
                    setEmpresasSelected([])
                    handler([])
                  }
                }}
              />
            <ListItemText primary='Todas' />
          </MenuItem>
          {empresas.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={empresasSelected.some(v=> v==name)} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}