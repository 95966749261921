import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import DialogTitle from '@mui/material/DialogTitle';
import theme from '../theme';
import Tab from './Tabs/Tab'
import { Alert, Box, Paper, Snackbar } from '@mui/material';
import Draggable from 'react-draggable';
import { useAuth } from '../auth/AuthProvider';


export default function FormDialog(props) {
  const [submit, setSubmit] = React.useState(false)
  const [handlerSnack, setHandlerSnack] = React.useState(false)
  const [ msgSnack, setMsgSnack ] = React.useState('')
  const [ sevSnack, setSevSnack ] = React.useState('')
  const auth = useAuth()

  function showNotify (msg,sev="success"){
    setMsgSnack(msg)
    setSevSnack(sev)
    setHandlerSnack(true)
  }
  function filterEmpresa(empresa){
    return empresa.id==props.id
  }
  const empresa = props.cod_empresa | props?.data?.filter(filterEmpresa)
  
  const resetSubmit =()=> setSubmit(false)
  return (
    <>
    <Snack open={handlerSnack} message={msgSnack} severity={sevSnack} handlerSnack={() => setHandlerSnack(false)} />
      <Dialog open={props.open} fullWidth={true}
          maxWidth={"auto"}>

          <DialogTitle  style={{ cursor: 'move' }} id="draggable-dialog-title">
            {!props.id ? `Nova empresa` : `Editar empresa: ${empresa? empresa[0].cod_empresa : ''}`}
          </DialogTitle>
          <DialogContent>
            <Tab {...props}  submit={submit} showNotify={showNotify} reset={resetSubmit}/>
          </DialogContent>
          <DialogActions>
            <Button sx={{ color: theme.palette.text.primary }} onClick={props.handlerClose}>Fechar</Button>
            <Button sx={{ color: theme.palette.text.primary }} disabled={!auth.hasPerm('empresa.edit')} onClick={()=>setSubmit(true)} type='button'> {!props.id ? 'Adicionar' : "Editar"}</Button>
          </DialogActions>
      </Dialog>
    </>
  );
}

const Snack = (props)=>{
  return  (
    <Snackbar
    open={props.open}
    autoHideDuration={6000}
    onClose={() => {props.handlerSnack()}}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }} 
    >
     <Alert  onClose={() => {props.handlerSnack()}} severity={props.severity ? props.severity : 'error'} sx={{ width: '100%' }}>
    {props.message}
  </Alert>
  </Snackbar>
  )
}


