import { ThemeProvider } from '@emotion/react'
import { Box, Button, CircularProgress, Container, IconButton } from '@mui/material'
import MUIDataTable from 'mui-datatables'
import React, { useState, useEffect, useLayoutEffect } from 'react'
import api from '../../services/api'
import theme from '../../themeDash'

import { useAuth } from '../../auth/AuthProvider'
import { redirect, useNavigate } from 'react-router-dom'
import EmpresaSelect from './EmpresaSelect'
import DateTimeToolbar from '../DateTimeToolbar'
import moment from 'moment'
import { textLabels } from '../textLabelsPT'
import { blueGrey, grey, lightBlue, lime, orange, red } from '@mui/material/colors'
import { Visibility } from '@mui/icons-material'
import FormDialog from './FormDialog'
export default function () {

  const [empresas, setEmpresas] = useState([])
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [loading, setLoading] = useState(false)
  const [openFormDialog, setOpenFormDialog] = useState(false)
  const [acks, setAcks] = useState([])


  useLayoutEffect(() => {
    document.title = "Relatórios de Alarmes - Dashboard NIIS IPV7"
  })


  const [data, setData] = useState({ results: [], total: [], limit: 30 })
  const columns = [
    {
      name: "event_id",
      label: "Evento ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      }
    },
    {
      name: "cod_empresa",
      label: "Empresa",
      options: {
        filter: true,
        sort: true,
        display: true,
        customFilterListOptions: { render: v => `Empresa: ${v}` },
      }
    },
    {
      name: "name",
      label: "Descrição",
      options: {
        filter: false,
        sort: false,
      }
    },

    {
      name: "hostid",
      label: "HostId",
      options: {
        display: false,
        filter: false,
        sort: false,
      }
    },
    {
      name: "hostname",
      label: "Host",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "ip",
      label: "IP",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "severity",
      label: "Severidade",
      options: {
        display: false,
        filter: false,
      }
    },
    {
      name: "clock",
      label: "Quando",
      options: {
        filter: false,
        customBodyRender: value => <span>{moment.unix(value).locale('pt-br').format('DD-MM-YYYY HH:mm:ss')}</span>,
        sort: true,
        sortOrder: 'desc',
      }
    },
    {
      name: "clock_r",
      label: "Finalizado",
      options: {
        filter: false,
        customBodyRender: value => <span>{value ? moment.unix(value).locale('pt-br').format('DD-MM-YYYY HH:mm:ss'): "-"}</span>,
        sort: true,
        sortOrder: 'desc',
      }
    },
    {
      name: "time_total",
      label: "Duração",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          const clock = data.results[dataIndex]?.clock
          const clock_r = data.results[dataIndex]?.clock_r
          const diffMilliseconds = moment.unix(clock_r).diff(moment.unix(clock));
          const humanizedDuration = moment.duration(diffMilliseconds).locale('pt-br').humanize();
          return <span>{!clock_r ? "" : humanizedDuration}</span>
        }
      }
    },
    {
      name: "online_users",
      label: "Usuários Online",
      options: {
        filter: false,
        sort: false,
        customBodyRender: users => users?.map(user => user?.name).join(', ')
      }
    },
    {
      name: "acknowledges",
      label: "Ack",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (value?.length ? <IconButton sx={{ color: grey[900] }} onClick={() => {
            setAcks(value)
            setOpenFormDialog(true)
          }
          }><Visibility /></IconButton> : "")
        }
      }
    },
    {
      name: "reaction_time",
      label: "Tempo de Reação",
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          const clock = data.results[dataIndex]?.clock
          const acks = data.results[dataIndex]?.acknowledges
          if (acks.length) {
            const acks_ipv7 = acks.filter(ack => ack.message.includes('Vistado por'))
            const ack = acks_ipv7.length ? acks_ipv7[0] : false

            if (!ack) return ""

            const diffMilliseconds = moment.unix(ack.clock).diff(moment.unix(clock));
            const humanizedDuration = moment.duration(diffMilliseconds).locale('pt-br').humanize();
            return <span
              //red if more than 45 minutes
              style={{ color: diffMilliseconds > 2700000 ? red[800] : 'black' }}
            >{humanizedDuration}</span>
          }
        },
        sortCompare: (order) => {
          return (obj1, obj2) => {
            const clock1 = obj1.rowData[7]
            const clock2 = obj2.rowData[7]
            const acks1 = obj1.rowData[11].filter(ack=> ack.message.includes('Vistado por')) ? obj1.rowData[11] : []
            const acks2 = obj2.rowData[11].filter(ack=> ack.message.includes('Vistado por')) ? obj2.rowData[11] : []
            const reactTime1 = acks1.length ? moment.unix(acks1[0].clock).diff(moment.unix(clock1)) : 0
            const reactTime2 = acks2.length ? moment.unix(acks2[0].clock).diff(moment.unix(clock2)) : 0
            return order === 'asc' ? reactTime1 - reactTime2 : reactTime2 - reactTime1
          }
        }
      }
    }
  ]

  const fetchData = (page = 0, limit = 30) => {
    setData({ results: [] })
    const skip = page * limit
    setLoading(true)
    api.post('report/alarmes', {
      page, skip, limit,
      empresas,
      start: startDate,
      end: endDate,
    })
      .then(res => {
        setData(data => ({ ...data, results: res.data.results, total: res.data.total, limit }))
      })
      .catch((err) => {
        console.log(err)
        setData({ results: [], total: [], limit: 30 })
      }).finally(() => {
        setLoading(false)

      })
  }


  const options = {
    search: false,
    textLabels: textLabels,
    filterType: 'checkbox',
    rowsPerPageOptions: [10, 30, 50, 100, 500, 1000, 5000],
    rowHover: false,
    print: true,
    selectableRows: 'none',
    download: true,
    //customToolbar,
    customToolbarSelect: () => { },
    setRowProps: (row, dataIndex, rowIndex) => {
      switch (Number(row[6])) {

        case 5:
          return {
            style: {
              backgroundColor: theme.palette.mode == 'dark' ? red[400] : red[300]
            },
          }
        case 4:
          return {
            style: {
              backgroundColor: theme.palette.mode == 'dark' ? orange[300] : orange[200]
            },
          }
        case 3:
          return {
            style: {
              backgroundColor: theme.palette.mode == 'dark' ? lime[300] : lime[200]
            },
          }
        case 2:
          return {
            style: {
              backgroundColor: theme.palette.mode == 'dark' ? lightBlue[300] : lightBlue[200],

            },
          }
        case 1:
          return {
            style: {
              backgroundColor: theme.palette.mode == 'dark' ? blueGrey[300] : blueGrey[200]

            },
          }
        default:
          break;
      }
    },
  }
  return (


    <Container sx={{ mt: 4, mb: 4 }}>
      <ThemeProvider theme={theme}>
        <FormDialog
          open={openFormDialog}
          handlerClose={() => {
            setOpenFormDialog(false)
            setAcks([])
          }}
          acks={acks}
        />

        <Box sm={12} lg={12}>
          <MUIDataTable
            title={<Box
              display="flex"
            >
              <EmpresaSelect handler={setEmpresas} />
              <DateTimeToolbar label={'Data Inicial'} handler={setStartDate} initValue={moment().subtract(1, 'days')} />
              <DateTimeToolbar label={'Data Final'} handler={setEndDate} />
              <Button onClick={() => fetchData()} disabled={
                !(startDate) || !(endDate) || !(empresas.length > 0) || loading
              }
                variant="contained" color="primary" sx={{ m: 1 }}>Buscar</Button>
            </Box>}
            data={data?.results}
            columns={columns}
            options={({
              ...options,
              textLabels: {
                ...textLabels, body: {
                  noMatch: loading ?
                    <CircularProgress color="info" />
                    : "Nenhum registro encontrado"
                }
              }
            })}
          />
        </Box>
      </ThemeProvider>
    </Container>

  )
}