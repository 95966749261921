import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import theme from '../../theme';
import { Alert, Autocomplete, Box, Checkbox, FormControlLabel, FormGroup, Snackbar } from '@mui/material';
import api from '../../services/api';


export const module = 'role'
export default function FormDialog(props) {
  const [handlerSnack, setHandlerSnack] = React.useState()
  const [permissions, setPermissions] = React.useState([])
  const [rolePermissions, setRolePermissions] = React.useState([])
  
  const item = {
    id: '',
    description: '',

  }
  const getRolePermssions=()=>{
    setRolePermissions([])
    if(!props.id) return
    api.get(`role-permission/find-by-role/${props.id}/`).then(res=>{setRolePermissions(res.data)
      
    })
    .catch(err=>console.log(err))
  }
  const  getPermissions = ()=>{
    api.get('permission/all').then(res=>{setPermissions(res.data)
     
    })
    .catch(err=>console.log(err))
  }
  const [state, setState] = React.useState(item);
  
  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  React.useEffect(()=>{
    getEditInfo()
    getPermissions()
    getRolePermssions()
  },[props.id, handlerSnack])



  const getEditInfo = ()=>{
    setState(item)
    if(!props.id) return
    api.get(`${module}/find/${props.id}`)
    .then(res=>{
      setState(res.data.__data__)
    

    }).catch(err=> setState(item))
  }
  const showNotify = (msg,sev="success")=>{
    setMsgSnack(msg)
    setSevSnack(sev)
    setHandlerSnack(true)
  }
  
  const [ msgSnack, setMsgSnack ] = React.useState('')
  const [ sevSnack, setSevSnack ] = React.useState('')
  const handleSubmit = (event)=>{
    const updatePerm = (id, cb)=>{
      const data = rolePermissions?.map(perm=>({ 'permission': perm.id, 'role' :id}))
      
      api.put(`role-permission/update/${id}/`,{permissions: data}).then(res=>{
        cb()
      }).catch(()=>{
        showNotify("Um erro ocorreu...",'error')
    })  
    }
    const handlerClose = ()=>{
      props.handlerClose()
    }
    event.preventDefault()
    if(!props.id){
      api.post(`${module}/create`, state)
      .then((res)=>{
        //check error in response
        if (res.data?.error) {
          showNotify(res.data?.error,'error')
          return
        }
        const id = res.data.__data__.id
          updatePerm(id,()=>{
            showNotify("Registro adicionado com sucesso.")
            handlerClose()
          })
          
      }).catch((err)=>{
          console.log(err)
          showNotify("Um erro ocorreu...",'error')
      })
    }else{
      api.put(`${module}/edit/${props.id}/`, state)
      .then(()=>{
        updatePerm(props.id,()=>{
          showNotify("Registro editado com sucesso.")
          handlerClose()
        })
    }).catch(()=>{
        handlerClose()
        showNotify("Um erro ocorreu...",'error')
    })
    }
  }

  return (
    <div>
      <Snack open={handlerSnack} message={msgSnack} severity={sevSnack} handlerSnack={()=>setHandlerSnack(false)}/>
      <Dialog open={props.open} fullWidth={true}
        maxWidth='sm'>
        <Box component="form" onSubmit={handleSubmit}  sx={{ mt: 1 }}>
        <DialogTitle>
            {!props.id ? 'Criar cargo' : "Editar cargo"}
        </DialogTitle>
        <DialogContent>
          <TextField
            required
            autoFocus
            margin="dense"
            id="id"
            name="id"
            label="ID"
            disabled
            type="text"
            fullWidth
            value={state.id}
            onChange={handleChange}
            variant="outlined"
            size="small"
          />        
          <TextField
          required
          size="small"
            margin="dense"
            id="description"
            name="description"
            label="Cargo"
            type="text"
            fullWidth
           value={state.description}
           onChange={handleChange}
            variant="outlined"
            />
             <Autocomplete
              multiple
              size="small"
              id="tags-outlined"
              options={permissions}
              onChange={(event, newValue) => {
                setRolePermissions(newValue);
              }}
             
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option.description}
              value={rolePermissions}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Permissões"
          />
        )}
      />  
        </DialogContent>
        <DialogActions>
          <Button sx={{color: theme.palette.text.primary}} onClick={props.handlerClose}>Cancelar</Button>
          <Button sx={{color: theme.palette.text.primary}} type='submit'> {!props.id ? 'Adicionar' : "Editar"}</Button>
        </DialogActions>
          </Box>
      </Dialog>
    </div>
  );
}

const Snack = (props)=>{
  return  (
    <Snackbar
    open={props.open}
    autoHideDuration={6000}
    onClose={() => {props.handlerSnack()}}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }} 
    >
     <Alert  onClose={() => {props.handlerSnack()}} severity={props.severity ? props.severity : 'error'} sx={{ width: '100%' }}>
    {props.message}
  </Alert>
  </Snackbar>
  )
}