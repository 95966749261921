import MUIDataTable from "mui-datatables";
import * as React from 'react'
import api from '../services/api'
import { Badge, Box, Container, IconButton, ThemeProvider, Tooltip } from "@mui/material";
import theme from "../themeDash";
import { grey, lightBlue, purple, red, teal } from "@mui/material/colors";
import { yellow } from "@mui/material/colors";
import { blueGrey } from "@mui/material/colors";
import { orange } from "@mui/material/colors";
import { lime } from "@mui/material/colors";
import moment from "moment";
import 'moment/locale/pt-br'
import ButtonsDash from "./Buttons-dash";
import { Button } from "@mui/material";
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import { green } from "@mui/material/colors";
import { Typography } from "@mui/material";
import FormDialog from "./FormDialog";
import MapIcon from '@mui/icons-material/Map';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import {useAuth} from '../auth/AuthProvider'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { useNavigate } from "react-router-dom";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import {NotifyButton} from "./Notify";
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import FormDialogHistory from './FormDialogHistory'
import FormDialogContato from './FormDialogContato'
import PhoneIcon from '@mui/icons-material/Phone';
import FormDialogEmpresa from '../Empresa/FormDialog'
import  NotifyOptionsProvider from './Notify'
import GraphDialog from "./GraphDialog";
import { pink } from "@mui/material/colors";
import ShowChartIcon from '@mui/icons-material/ShowChart';
import ArticleIcon from '@mui/icons-material/Article';
import FormDialogPPI from './FormDialogPPI'


export default function () {

  const [problems, setProblems] = React.useState()
  const [handlerDialog, setHandlerDialog] = React.useState(false)
  const [handlerDialogHistory, setHandlerDialogHistory] = React.useState(false)
  const [handlerDialogContato, setHandlerDialogContato] = React.useState(false)
  const [handlerDialogEmpresa, setHandlerDialogEmpresa] = React.useState(false)
  const [handlerDialogPPI, sethandlerDialogPPI] = React.useState(false)
  const [handlerGraph, setHandlerGraph] = React.useState(false)
  const [severity, setSeverity] = React.useState(false)
  const [hostid, setHostid] = React.useState('')
  const [codEmpresa, setCodEmpresa] = React.useState('')
  const [tags, setTags] = React.useState('')
  const [ppi, setPpi] = React.useState('')
  const [historyInfo, setHistoryInfo] = React.useState({})
  const [eventId, setEventId] = React.useState('')
  const [itemid, setItemid] = React.useState('')
  const [update, setUpdate] = React.useState('')
  const [detailAlarms, setDetailAlarms] = React.useState('')
  //const [isSelectRow, setIsSelectRow] = React.useState(false)
  const isSelectRow = React.useRef(false)

  const [id, setId] = React.useState()
  const auth = useAuth()
  const [empresas, setEmpresas] = React.useState([])
  const navigate = useNavigate()
  const getFilter = (col) => {
    const filter = JSON.parse(localStorage.getItem('filtersAlarm'))
    if (filter) {
      return filter[col]
    }
    return []

  }
  const getEmpresas = () => api.get('/empresa/all').then(res => setEmpresas(res.data.filter(empresa=> empresa.status ==1).map(empresa => empresa.cod_empresa)))
  const getPage= (col) => {
    const filter = JSON.parse(localStorage.getItem('Page'))
    if (filter) {
      return filter[col]
    }
    return 10

  }


  const getProblems = () => {
    api.get('/dashboard/problems')
      .then(res => setProblems(res.data))
      .catch((err) => {
        if(err.response.status==401){
          navigate('/401')
        }
        setProblems()
      })

  }
  const columns = [
    {
      name: "event_id",
      label: "Evento ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      }
    },
    {
      name: "cod_empresa",
      label: "Empresa",
      options: {
        filter: true,
        sort: true,
        display: true,
        customFilterListOptions: { render: v => `Empresa: ${v}` },
        filterOptions: {
          names: empresas,
        }
      }
    },
    {
      name: "name",
      label: "Descrição",
      options: {
        filter: false,
        sort: false,
      }
    },
   
    {
      name: "hostid",
      label: "HostId",
      options: {
        display: false,
        filter: false,
        sort: false,
      }
    },
    {
      name: "hostname",
      label: "Host",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "ip",
      label: "IP",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "clock",
      label: "Quando",
      options: {
        filter: false,
        customBodyRender: value => <span>{moment(value).locale('pt-br').format('DD-MM-YYYY HH:mm:ss')} ({moment(value).locale('pt-br').fromNow()})</span>,
        sort: true,
        sortOrder: 'desc',
      }
    },
    {
      name: "severity",
      label: "Severidade",
      options: {
        filter: true,
        sort: false,
        display: false,
        customFilterListOptions: { render: v => `Severidade: ${v}` },
        filterOptions: {
          names: [1,2,3,4,5,6,7],
        },
      }
    },
    {
      name: "acks",
      label: "Vistos",
      options: {
        filter: true,
        sort: true,
        display: false,
        customFilterListOptions: { render: v => `Vistos: ${v}` },
        
      }
    },
    {
      name: "action",
      label: "Action",
      options: {
        filter: false,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          const codEmpresa = problems[dataIndex]?.cod_empresa
          const eventId = problems[dataIndex]?.event_id
          const acks = problems[dataIndex]?.acks
          
          
          if (problems[dataIndex].severity == 6 || problems[dataIndex].severity == 7 || problems[dataIndex].event_id == 0){
            return(
              
              <>
              <Tooltip title={ problems[dataIndex].tags || "Reconhecer alarme"} >
                <IconButton disabled={!auth.hasPerm('problem.edit')} sx={{
                  backgroundColor:  acks> 0 ? grey[900] : ""
                }}
                  onClick={
                    () => {
                      const id = problems[dataIndex].event_id
                      const hostid = problems[dataIndex].hostid
                      
                      setId(id)
                      setHostid(hostid)
                      const tags = problems[dataIndex].tags
                      setTags(tags)
                      setSeverity(problems[dataIndex].severity)
                      setCodEmpresa(problems[dataIndex].cod_empresa)
                      setEventId(problems[dataIndex].event_id)
                      setDetailAlarms(`${problems[dataIndex].hostname} - ${problems[dataIndex].name}`)
                      setHandlerDialog(true)
                      console.log(`${problems[dataIndex].hostname} - ${problems[dataIndex].name}`)
                    }
                  }
                  >

                  <Badge badgeContent={acks} color="success" >
                    { acks > 0 ?
                    <AssignmentTurnedInIcon sx={{ color: "white" }} /> : 
                    <AssignmentLateIcon sx={{ color: grey[900] }} />
                  }
                  </Badge>
                
                </IconButton>
              </Tooltip>
              { problems[dataIndex]?.tags_ppi>0 ?
                  <Tooltip title="Procedimento do alarme">
                  
                  <IconButton sx={{
                     backgroundColor:  grey[900] ,
                     color: "white"

                   }}
                   onClick={()=>{
                    setPpi(problems[dataIndex]?.tags_ppi)
                    sethandlerDialogPPI(true)
                    }}
                    >
                    <ArticleIcon/>
                  </IconButton>
                    </Tooltip>
                  : "" }
              </>
            )
          }
          return (
            <Box sx={{
              display: 'flex',
            }}>
         {acks > 0 ?
                <Tooltip title={ problems[dataIndex].tags || "Reconhecer alarme"} >
                <IconButton disabled={!auth.hasPerm('problem.edit')} sx={{
                  backgroundColor:  acks> 0 ? grey[900] : grey[100],
                  color: acks > 0 ? "white" : grey[900]
                }}
                  onClick={
                    () => {
                      const id = problems[dataIndex].event_id
                      setId(id)
                      setHostid(false)
                      const tags = problems[dataIndex].tags
                      setTags(tags)
                      
                      setCodEmpresa(problems[dataIndex].cod_empresa)
                      setEventId(problems[dataIndex].event_id)
                      setDetailAlarms(`${problems[dataIndex].hostname} - ${problems[dataIndex].name}`)
                      setHandlerDialog(true)
                      console.log(`${problems[dataIndex].hostname} - ${problems[dataIndex].name}`)
                    }
                  }
                  >

                  <Badge badgeContent={acks} color="success" >
                    <AssignmentTurnedInIcon sx={{ color: "white" }} />
                  </Badge>
                </IconButton>
              </Tooltip>
          
          :
          <Tooltip title={ problems[dataIndex].tags || "Reconhecer alarme"} >

              <IconButton disabled={!auth.hasPerm('problem.edit')} sx={{ color: grey[900] }} 
                onClick={
                  () => {
                    const tags = problems[dataIndex].tags
                    
                    setTags(tags)
                    const id = problems[dataIndex].event_id
                    setId(id)
                    setCodEmpresa(problems[dataIndex].cod_empresa)
                    setDetailAlarms(`${problems[dataIndex].hostname} - ${problems[dataIndex].name}`)
                    setEventId(problems[dataIndex].event_id)
                    setHandlerDialog(true)
                  }
                }
                >
                <AssignmentLateIcon sx={{ color: grey[900] }} />
              </IconButton>
              </Tooltip>
          }      <Tooltip title="Histórico do alarme. (Max: 36hrs)">

                <IconButton
                sx={{ color: grey[900] }} 
                onClick={()=>{
                  setHistoryInfo({
                    cod_empresa: problems[dataIndex].cod_empresa,
                    triggerid: problems[dataIndex].triggerid
                  })
                  setHandlerDialogHistory(true)
                }}
                >
                  <RotateLeftIcon/>
                </IconButton>
                  </Tooltip>
                  <Tooltip title="Grafico do Item">

                <IconButton
                sx={{ color: grey[900] }} 
                onClick={()=>{
                  setCodEmpresa(problems[dataIndex].cod_empresa)
                  setItemid(problems[dataIndex].itemid)
                  
                  setHandlerGraph(true)
                }}>
                  <ShowChartIcon />
                </IconButton>
                </Tooltip>
                <Tooltip title="Informações da Empresa">

                <IconButton sx={{ color: grey[900] }}
                //target="_blank" href={`${problems[dataIndex].info}`}
                onClick={()=>{
                  const cod_empresa = problems[dataIndex].cod_empresa
                  api.get(`/empresa/find/cod_empresa/${cod_empresa}`).then(res=>{
                    
                    setId(res.data.__data__.id)
                    setCodEmpresa(cod_empresa)
                    setHandlerDialogEmpresa(true)
                  })
                }}
                >
                  <LiveHelpIcon/>
                </IconButton>
                </Tooltip>
                <Tooltip title="Informações de contato">
                  
                <IconButton sx={{ color: grey[900] }}
                 onClick={()=>{
                   
                   setCodEmpresa(problems[dataIndex].cod_empresa)
                   setHandlerDialogContato(true)
                  }}
                  >
                  <PhoneIcon/>
                </IconButton>
                  </Tooltip>
                  { problems[dataIndex]?.tags_ppi>0 ?
                  <Tooltip title="Procedimento do alarme">
                  
                  <IconButton sx={{

                    backgroundColor:  grey[900] ,
                    color: "white"
                   }}
                   onClick={()=>{
                    setPpi(problems[dataIndex]?.tags_ppi)
                    sethandlerDialogPPI(true)
                    }}
                    >
                    <ArticleIcon/>
                  </IconButton>
                    </Tooltip>
                  : "" }
              </Box>
            )
        },
        sort: true,
        display: true,
      }
    },
    {
      name: "tags_ppi",
      label: "PPI",
      options: {
        filter: false,
        sort: false,
        display: false,
      }
    },
  ];
  const options = {
    customToolbar: () => {return(<>
        <NotifyButton></NotifyButton>
        <Tooltip title="Remover todos os filtros">
          <IconButton onClick={()=>{localStorage.removeItem('alarms'); location.reload()}} ><RemoveCircleOutlineIcon /></IconButton>
        </Tooltip>
      </>
    )},
    onChangeRowsPerPage:(n)=>{
      localStorage.setItem('Page', JSON.stringify(n))
    },
    // onViewColumnsChange: (col,act) => {
    //   console.log(col,act)
    // },
    storageKey: 'alarms',
    onFilterChange: (col, data) => {
      localStorage.setItem('filtersAlarm', JSON.stringify(data))},
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => 
    <Tooltip title="Reconhecimento em massa">

    <IconButton 
    disabled={!auth.hasPerm('problem.edit')}
    onClick={() => {

      const eventsIds = selectedRows.data.map(row => problems[row.dataIndex].event_id)
      const details = selectedRows.data.map(row => `${problems[row.dataIndex].hostname} - ${problems[row.dataIndex].name}`)
      const empresas = selectedRows.data.map(row => problems[row.dataIndex].cod_empresa)
      const severities = selectedRows.data.map(row => problems[row.dataIndex].severity)
      //const detailAlarms = selectedRows.data.map(row => ${problems[row.dataIndex].nome)
      if (severities.every(v => v == 6 ||  v == 7)) { 
        alert("Não é possivel vistar em grupo alarmes de erro de Backup ou erro de API!")
        return;
      }
      if (!empresas.every(v => v == empresas[0])) {
        alert("Selecione apenas alarmes referentes a mesma empresa!")
        return;
      }
      setEventId(eventsIds)
      setDetailAlarms(details)
      setCodEmpresa(empresas[0])
      setHandlerDialog(true)

    }}><AssignmentLateIcon /></IconButton>
        </Tooltip>,
    filterType: 'checkbox',
    
    rowsPerPage: getPage(),
    rowsPerPageOptions: [10, 30, 100, 300,600,1000],
    rowHover: false,
    print: false,
    download: true,
    setRowProps: (row, dataIndex, rowIndex) => {

      switch (row[7]) {
        case 7:
          return {
            style: {
              backgroundColor: theme.palette.mode == 'dark' ? pink[300] : pink[200]
            },
          }
        case 5:
          return {
            style: {
              backgroundColor: theme.palette.mode == 'dark' ? red[400] : red[300]
            },
          }
        case 6:
          return {
            style: {
              backgroundColor: theme.palette.mode == 'dark' ? red[400] : red[300]
            },
          }
        case 4:
          return {
            style: {
              backgroundColor: theme.palette.mode == 'dark' ? orange[300] : orange[200]
            },
          }
        case 3:
          return {
            style: {
              backgroundColor: theme.palette.mode == 'dark' ? lime[300] : lime[200]
            },
          }
        case 2:
          return {
            style: {
              backgroundColor: theme.palette.mode == 'dark' ? lightBlue[300] : lightBlue[200],
    
            },
          }
        case 1:
          return {
            style: {
              backgroundColor: theme.palette.mode == 'dark' ? blueGrey[300] : blueGrey[200]

            },
          }
        default:
          break;
      }
    },
    onTableChange: (action, tableState) =>{
      const { page, rowsPerPage, searchText } = tableState
        if (tableState.selectedRows.data.length > 0) {
          isSelectRow.current = true
          
        } else {
          isSelectRow.current = false
          
        }  
    },
  };

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (isSelectRow.current){
        //setTimeout(() => setUpdate(true), 300*1000)
        
        return;
      }
      getProblems();
      console.log('Update problems ' + new Date());
    }, 60*1000);
  
    return () => {
      clearInterval(interval);
    };
  }, [auth.user]);
  
  React.useEffect(() => {
    getProblems();
    setUpdate(false);
    getEmpresas()
  }, [update]);

  return (
    <NotifyOptionsProvider problems={{problems}}>

    <Container maxWidth={false} sx={{ mt: 4, mb: 4, height:'auto' }}>
      <FormDialog open={handlerDialog} codEmpresa={codEmpresa} tags={tags} eventId={eventId} hostid={hostid} severity={severity} detailAlarms={detailAlarms} handlerClose={() => {
        setHandlerDialog(false)
        setUpdate(true)
      }} />
      <GraphDialog open={handlerGraph}  codEmpresa={codEmpresa} itemid={itemid}  handlerClose={() => {
        setHandlerGraph(false)
      }}/>
      <FormDialogHistory open={handlerDialogHistory} info={historyInfo}  handlerClose={() => {
        setHandlerDialogHistory(false)
      }} />
      <FormDialogContato open={handlerDialogContato}  codEmpresa={codEmpresa}   handlerClose={() => {
        setHandlerDialogContato(false)
      }} />
      <FormDialogEmpresa open={handlerDialogEmpresa}  codEmpresa={codEmpresa} id={id} handlerClose={()=>setHandlerDialogEmpresa(false)} />

      <FormDialogPPI open={handlerDialogPPI}  id={ppi} handlerClose={()=>{

        sethandlerDialogPPI(false)} 
        
        }/>
      <ThemeProvider theme={theme}>
        <Box sm={12} lg={12}>
          <MUIDataTable
            
            title={"Alarmes"}
            data={problems}
            columns={columns}
            options={options}
            
            />
        </Box>
      </ThemeProvider>
    </Container>
    </NotifyOptionsProvider>

  )
}

