import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import theme from '../../theme';
import { Alert, Box, Checkbox, FormControlLabel, FormGroup, Snackbar } from '@mui/material';

import { Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import moment from "moment";
import 'moment/locale/pt-br'


export default function FormDialog(props) {
 

  const handlerClose = ()=>{
    props.handlerClose()
  }


  return (
    <div>
      <Dialog open={props.open} fullWidth={true}
        maxWidth='sm'>
       
        <DialogTitle>
            Histórico de Reconhecimento de alarmes:
        </DialogTitle>
        <DialogContent>

        
          {props?.acks?.map((ack,i)=>
            <Alert key={i} sx={{mt: 1, backgroundColor: grey[1000]}} severity="info">
              <Typography>{moment.unix(ack.clock).locale('pt-br').format('DD-MM-YYYY HH:mm:ss')}</Typography>
              {ack.message} </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button sx={{color: theme.palette.text.primary}} onClick={handlerClose}>Cancelar</Button>
        </DialogActions>
      
      </Dialog>
    </div>
  );
}

