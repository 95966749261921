import React, { useRef, useEffect, useCallback } from 'react';
import { Box, FormControl, InputLabel, MenuItem, Select, TextField, TextareaAutosize } from '@mui/material';
import api from '../../services/api';
export  const status = [
  { id: 0, nome: 'Inativo' },
  { id: 1, nome: 'Ativo' },
  { id: 2, nome: 'Em ativação' },
  { id: 3, nome: 'Cancelado' },
  { id: 4, nome: 'Suspenso' },

]
export default function FormDialog(props) {
  const submitButtonRef = useRef(null);
  const { showNotify, submit } = props;
  
  useEffect(() => {
    if (submit && submitButtonRef.current) {
      submitButtonRef.current.click();
      props.reset()
    }

  }, [submit]);

  const item = {
    cod_empresa: '',
    cnpj: '',
    razao_social: '',
    nome_fantasia: '',
    site: '',
    email: '',
    responsavel: '',
    observacao: '',
    observacao_dash: '',
    asn: '',
    status: 1,
  };

  const [state, setState] = React.useState(item);

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    if (props.open) {
      getEditInfo();
    }
  }, [props.open]);

  const getEditInfo = () => {
    setState(item);
    if (!props.id) return;
    api.get(`empresa/find/${props.id}`)
      .then(res => {
        let data = res.data.__data__;
        setState(data);
      }).catch(err => setState(item));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const handlerClose = () => {
      props.reset();
      props.handlerClose()
    };

    if (!props.id) {
      api.post('empresa/create', state)
        .then(() => {
          showNotify("Registro adicionado com sucesso.");
          handlerClose();
        }).catch((err) => {
          if (err.response.data.detail == "Item alredy exists.") {
            showNotify("Já existe uma empresa com esse código", 'warning');
          } else {
            showNotify("Um erro ocorreu...", 'error');
            handlerClose();

          }
        });
    } else {
      api.put(`empresa/edit/${props.id}/`, state)
        .then(() => {
          showNotify("Registro editado com sucesso.");
          handlerClose();
        }).catch((err) => {
          handlerClose();
          console.log(err);
          showNotify("Um erro ocorreu...", 'error');
        });
    }
  }

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>

      <FormControl fullWidth>
      <InputLabel id="select-label">Estado da Empresa:</InputLabel>
        <Select
          required
          size="small"
          labelId="select-label"
          id="status"
          name='status'
          value={state.status}
          label="Status"
          onChange={handleChange}
        >

          {status ? status.map((servico, i) =>
            <MenuItem key={servico.id} value={servico.id}>{servico.nome}</MenuItem>
          ) : ''}
        </Select>
        <button type="submit" ref={submitButtonRef} style={{ display: 'none' }} />
      </FormControl>
      <TextField
        required
        size="small"
        margin="dense"
        id="cod_empresa"
        name="cod_empresa"
        label="Código da Empresa"
        type="number"
        fullWidth
        value={state.cod_empresa}
        onChange={handleChange}
        variant="outlined"
      />
      <TextField
        required
        size="small"
        margin="dense"
        id="razao_social"
        name="razao_social"
        label="Razão Social"
        type="text"
        fullWidth
        value={state.razao_social}
        onChange={handleChange}
        variant="outlined"
      />
      <TextField
        required
        size="small"
        margin="dense"
        id="nome_fantasia"
        name="nome_fantasia"
        label="Nome Fantasia"
        type="text"
        fullWidth
        value={state.nome_fantasia}
        onChange={handleChange}
        variant="outlined"
      />
      <TextField
        required
        size="small"
        margin="dense"
        id="cnpj"
        name="cnpj"
        label="CNPJ"
        type="number"
        fullWidth
        value={state.cnpj}
        onChange={handleChange}
        variant="outlined"
      />
      <TextField

        size="small"
        margin="dense"
        id="site"
        name="site"
        label="Site"
        type="text"
        fullWidth
        value={state.site}
        onChange={handleChange}
        variant="outlined"
      />
      <TextField

        size="small"
        margin="dense"
        id="email"
        name="email"
        label="Email"
        type="text"
        fullWidth
        value={state.email}
        onChange={handleChange}
        variant="outlined"
      />
      <TextField

        size="small"
        margin="dense"
        id="responsavel"
        name="responsavel"
        label="Responsável"
        type="text"
        fullWidth
        value={state.responsavel}
        onChange={handleChange}
        variant="outlined"
      />
      <TextField

        size="small"
        margin="dense"
        id="asn"
        name="asn"
        label="ASN"
        type="text"
        fullWidth
        value={state.asn}
        onChange={handleChange}
        variant="outlined"
      />
      <TextField

        multiline
        rows={8}
        size="small"
        margin="dense"
        id="observacao"
        name="observacao"
        label="Observações"
        type="text"
        fullWidth
        value={state.observacao}
        onChange={handleChange}
        variant="outlined"
      />
      <TextField
        size="small"
        rows={5}
        multiline
        margin="dense"
        id="observacao_dash"
        name="observacao_dash"
        label="Observação Alarme"
        type="text"
        fullWidth
        value={state.observacao_dash}
        onChange={handleChange}
        variant="outlined"
      />

    </Box>
  );
}


