import { Autocomplete, FormControl, MenuItem, TextField } from '@mui/material';
import React,{useState} from 'react'
import api from '../services/api'


export default function EmpresaSelect({handler, multiple=false}) {
    const [empresas, setEmpresas] = useState([])
    const [empresa, setEmpresa] = React.useState(null)
    React.useLayoutEffect(() => {
        api.get('map/all').then(res => {
          setEmpresas(res.data)
        
        })
      }, [])
  return (
    <FormControl 
    sx={{ width: 200, m:1}}
    >
      <Autocomplete
        multiple={multiple}
        size="small"
        id="empresas-outlined"
        options={empresas}
        onChange={(event, newValue) => {
          setEmpresa(newValue);
          if(newValue==null){ 
            handler(null)
            return
          }
          handler(newValue.cod_empresa)
        }}
        key={'mapList'}
        isOptionEqualToValue={(option, value) => option.cod_empresa == value.cod_empresa}
        getOptionLabel={(option) => option.cod_empresa.toString()}
        value={empresa}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            label="Empresa"
          />
        )}
      />
    </FormControl>
  )
}

export function SevSelect({ handler }) {
  const severities = [3, 4, 5, 6];
  const [value, setValue] = useState('');

  return (
    <TextField
      id="select-sev"
      label="Severidade"
      size='small'
      select
      sx={{
        width:100,
        m:1
      }}
      value={value}
      onChange={(event) => {
        const newValue = event.target.value;
        handler(newValue);
        setValue(newValue);
      }}
    >
      <MenuItem key={null} value={null} >
        Limpar
      </MenuItem>
      {severities.map((severity) => (
        <MenuItem key={severity} value={severity}>
          {severity}
        </MenuItem>
      ))}
    </TextField>
  );
}