import React, { memo } from 'react';
import { Box, IconButton, Paper, Typography } from '@mui/material';
import styled from 'styled-components';
import { blue, orange, pink, lime, grey, deepPurple, red } from '@mui/material/colors';
import { AreaChart, Line, LineChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, stop, linearGradient } from 'recharts';
import theme from '../theme';
import api from '../services/api';
import moment from 'moment';
import { Handle, Position, } from 'react-flow-renderer';
import { CloseOutlined } from '@mui/icons-material';
import { Resizable } from 'react-resizable';


const transformArray = (items) => {
  const groupedItems = items.reduce((result, item) => {
    const { itemid, clock, value } = item;
    const roundedClock = roundToNearestTime(clock);

    if (!result[roundedClock]) {
      result[roundedClock] = { clock: roundedClock };
    }

    result[roundedClock][itemid] = value;
    return result;
  }, {});

  // Convertendo o objeto de grupos de volta para um array de objetos
  const transformedArray = Object.values(groupedItems);

  return transformedArray;
};

const findMinMaxClock = (items) => {
  const clockMap = {};

  // Primeiro, vamos inicializar o clockMap para cada propriedade item
  for (const item of items) {
    for (const key in item) {
      if (key !== 'clock') {
        if (!clockMap[key]) {
          clockMap[key] = { minClock: item.clock, maxClock: item.clock };
        } else {
          if (item.clock < clockMap[key].minClock) {
            clockMap[key].minClock = item.clock;
          }
          if (item.clock > clockMap[key].maxClock) {
            clockMap[key].maxClock = item.clock;
          }
        }
      }
    }
  }

  // Em seguida, vamos encontrar o menor e o maior clock de todas as propriedades
  let minClock = Number.MAX_SAFE_INTEGER;
  let maxClock = 0;

  for (const key in clockMap) {
    if (clockMap[key].minClock < minClock) {
      minClock = clockMap[key].minClock;
    }
    if (clockMap[key].maxClock > maxClock) {
      maxClock = clockMap[key].maxClock;
    }
  }

  return { minClock, maxClock };
};
const roundToNearestTime = (timestamp) => {
  const date = new Date(timestamp * 1000);
  const seconds = date.getSeconds();
  const roundedSeconds = Math.ceil(seconds / 60) * 60; // Round to the next 30 seconds
  date.setSeconds(roundedSeconds);
  date.setMilliseconds(0);

  if (roundedSeconds === 60) {
    date.setMinutes(date.getMinutes() + 1); // If rounded to 60 seconds, move to the next minute
    date.setSeconds(0);
  }

  return Math.floor(date.getTime() / 1000);
};
const Node = styled.div`
  padding: 1px;
  border-radius: 5px;
  background: ${(props) => props.theme.nodeBg};
  color: ${(props) => props.theme.nodeColor};
  border: 0px solid ${(props) => (props.selected ? props.theme.primary : props.theme.nodeBorder)};

  .react-flow__handle {
    background: ${(props) => props.theme.primary};
    width: 0px;
    height: 0px;
    border: 0px;

  }

  .react-flow__handle {
    left: 15px;
    top: 0px;
  }
`;
const convertUnixToDateTime = (unixTimestamp) => moment.unix(unixTimestamp).format('DD-MM HH:mm');
const GraphNode = memo(({ data, selected, xPos, yPos }) => {
  const [graphData, setGraphData] = React.useState([]);
  const listColor = [blue[900], orange[900], pink[900], lime[900], deepPurple[900], red[900]];

  const [edgesX, setEdgesX] = React.useState({})

  React.useEffect(() => {
    const fetchData = () => {

      const { cod_empresa, itemid } = data;
      api
        .post('map/items-data', {
          cod_empresa: cod_empresa,
          itemid: itemid,
        })
        .then((res) => {
          const tmp_data = res.data.map((item) => ({
            ...item,
            value: Number((Number(item.value) / 1000000000).toFixed(2)),
            //clock: convertUnixToDateTime(Number(item.clock)),
            clock: Number(item.clock)
          }));

          setGraphData(transformArray(tmp_data));
          setEdgesX(findMinMaxClock(tmp_data))
        });

    };

    // Chama a função fetchData imediatamente
    fetchData();


    const intervalId = setInterval(fetchData, 60000);

    // Função de limpeza para cancelar o intervalo quando o componente é desmontado
    return () => {
      clearInterval(intervalId);
    };
  }, [data.itemid, data.display]);

  return (
    <Paper
      elevation={4}
      sx={
        {
          color: theme.palette.mode === 'dark' ? '#ede7f6' : grey[900],


        }
      }
    >
      <IconButton
        sx={{
          position: 'absolute',
          top: '8px',
          right: '8px',
          zIndex: 9999
        }}
        onClick={() => data.close()}
      >
        <CloseOutlined></CloseOutlined>
      </IconButton>
      <Node selected={selected}>
        {graphData && (
          <>
            {data.title?.map((title, i) =>
              <Typography variant="body" component={'div'} color={listColor[i]} style={{ textAlign: 'center', m: 2, }}>
                {title}
              </Typography>
            )}

            <AreaChart
              width={1200}
              height={300}
              data={graphData}
              margin={{
                right: 50,
                left: 50,
                bottom: 20,
              }}
            >
              <CartesianGrid fillOpacity={0.6} />
              <XAxis
                dataKey="clock"
                tickFormatter={convertUnixToDateTime}
                allowDataOverflow={false}
                //interval={5}
                domain={[edgesX.minClock, edgesX.maxClock]}
              />
              <YAxis 
              
              unit={'Gbps'}></YAxis>
              {/* <Tooltip 
      content={<CustomTooltip />}
    /> */}
              <defs>
                {listColor.map((color, i) => (
                  <linearGradient id={`${i}graph`} x1="0" y1="0" x2="0" y2="1" key={i}>
                    <stop offset="0%" stopColor={color} stopOpacity={0.8} />
                    <stop offset="100%" stopColor={color} stopOpacity={0.1} />
                  </linearGradient>
                ))}
              </defs>
              {data.itemid?.map((item, i) => (
                <Area
                  connectNulls
                  type="linear"
                  unit={'Gbps'}
                  strokeWidth={2}
                  dataKey={item}
                  stackId={i}
                  name={data?.title[i]}
                  
                  stroke={listColor[i]}
                  fill={`url(#${i}graph)`}
                  key={i}
                />
              ))}
            </AreaChart>
          </>
        )}
      </Node>


    </Paper>
  );
});

const CustomTooltip = ({ active, payload, label }) => {

  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip" >
        {payload?.map(v =>
          <p className="label">{v.name.includes('TX') ? 'TX' : 'RX'} : {v.value}Gbps</p>
        )}
        <p className="label">{convertUnixToDateTime(payload[0].payload.clock)} </p>
      </div>
    );
  }

  return null;
};


export default GraphNode